import {
  Flex,
  FormControl,
  HStack,
  Icon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'
import { MdTimer } from 'react-icons/md'

type Props = {
  onChange: (value: number) => void
  value: number
}

const FollowUp: React.FC<Props> = ({ onChange, value }) => {
  return (
    <VStack align="flex-start" gap={0}>
      <Flex align="center">
        <Icon as={MdTimer} mr={2} />
        <Text fontWeight="medium" fontSize="md">
          <Trans>Avoid counting forwarded calls as repeat call</Trans>
        </Text>
      </Flex>
      <Text color="gray.600" fontSize="sm">
        <Trans>
          This setting prevents a forwarded call from being counted as a repeat
          call if it occurs within X minutes. If you change this setting and you
          want the updated value you need to update all segments and widgets
          where the repeat call filter is used. To update the segment or widget
          simply remove the repeat call filter and add it again. If you include
          forwarded calls as repeat calls set this to 0.
        </Trans>
      </Text>
      <Flex>
        <FormControl mt={2}>
          <HStack>
            <Text fontSize="sm">
              <Trans>Follow-up call at least after</Trans>
            </Text>
            <NumberInput
              min={0}
              max={100}
              size="xs"
              onChange={(_, valueAsNumber) => {
                onChange(valueAsNumber * 60)
              }}
              value={value / 60}
            >
              <NumberInputField h="24px" w="48px" p={2} />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <Text fontSize="sm">
              <Trans>minutes</Trans>
            </Text>
          </HStack>
        </FormControl>
      </Flex>
    </VStack>
  )
}

export default FollowUp
