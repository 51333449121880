import request, { ResponseError } from '@capturi/request'
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { Direction } from '../DirectionSelector'

const CACHE_KEY = ['importers', 'focalscopeSftp']

type focalscopeSftpFilters = {
  blacklistedEmails: string[]
  blacklistedDomains: string[]
  whitelistedDirections: Direction[]
  whitelistedFolders: string[]
  blacklistedFolders: string[]
  blacklistedSubjectPrefix: string[]
}

export type ListFocalscopeSftpResponse = {
  auth: {
    username: string
    password: string
  }
  filters: focalscopeSftpFilters
  lastRunStarted: Date
  lastRunEnded: Date
  continuationToken: string
  customFields: string[]
  friendlyName: string
  uid: string
  organizationUid: string
  isActive: boolean
}

export type AddFocalscopeSftpPayload = {
  auth: {
    username: string
    password: string
  }
  friendlyName: string
  continuationToken: string
}
export type PatchFocalscopeSftpPayload = Partial<{
  auth: Partial<{
    secToken: string
    subdomain: string
  }>
  friendlyName: string
  continuationToken: string
  customFields: string[]
  filters: Partial<focalscopeSftpFilters>
}>

export const useFocalscopeSftpImporter = (): UseQueryResult<
  ListFocalscopeSftpResponse[],
  ResponseError
> =>
  useQuery({
    queryKey: CACHE_KEY,
    queryFn: () =>
      request.get<ListFocalscopeSftpResponse[]>(
        'importer/focalscope-sftp/list',
      ),
  })

export const useAddFocalscopeSftpImporter = (): UseMutationResult<
  ListFocalscopeSftpResponse,
  ResponseError,
  AddFocalscopeSftpPayload,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: CACHE_KEY,
    mutationFn: (model: AddFocalscopeSftpPayload) =>
      request.post('importer/focalscope-sftp/add', { json: model }),
    onSuccess: (importer) => {
      queryClient.setQueryData<ListFocalscopeSftpResponse[]>(
        CACHE_KEY,
        (oldData) => {
          if (!oldData) {
            return [importer]
          }
          return [...oldData, importer]
        },
      )
    },
  })
}

type UpdateImporter = { uid: string; payload: PatchFocalscopeSftpPayload }
export const useUpdateFocalscopeSftpImporter = (): UseMutationResult<
  ListFocalscopeSftpResponse,
  ResponseError,
  UpdateImporter,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: CACHE_KEY,
    mutationFn: ({ uid, payload }) =>
      request.patch(`importer/focalscope-sftp/${uid}`, { json: payload }),
    onSuccess: (importer, { uid }) => {
      queryClient.setQueryData<ListFocalscopeSftpResponse[]>(
        CACHE_KEY,
        (oldData) => {
          if (!oldData) {
            return [importer]
          }

          return oldData.map((d) => (d.uid === uid ? { ...d, ...importer } : d))
        },
      )
    },
  })
}
export const useStartFocalscopeSftpImporter = (): UseMutationResult<
  ListFocalscopeSftpResponse,
  ResponseError,
  string,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: CACHE_KEY,
    mutationFn: (uid: string) =>
      request.post(`importer/focalscope-sftp/${uid}/start`),
    onSuccess: (_, uid) => {
      queryClient.setQueryData<ListFocalscopeSftpResponse[]>(
        CACHE_KEY,
        (oldData) => {
          if (!oldData) {
            return []
          }
          return oldData.map((d) =>
            d.uid === uid ? { ...d, isActive: true } : d,
          )
        },
      )
    },
  })
}
export const useStopFocalscopeSftpImporter = (): UseMutationResult<
  ListFocalscopeSftpResponse,
  ResponseError,
  string,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: CACHE_KEY,
    mutationFn: (uid: string) =>
      request.post(`importer/focalscope-sftp/${uid}/stop`),
    onSuccess: (_, uid) => {
      queryClient.setQueryData<ListFocalscopeSftpResponse[]>(
        CACHE_KEY,
        (oldData) => {
          if (!oldData) {
            return []
          }
          return oldData.map((d) =>
            d.uid === uid ? { ...d, isActive: false } : d,
          )
        },
      )
    },
  })
}
