import request, { ResponseError } from '@capturi/request'
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

export const regions = [
  'mypurecloud.com',
  'mypurecloud.ie',
  'mypurecloud.com.au',
  'mypurecloud.jp',
  'mypurecloud.de',
  'usw2.pure.cloud',
  'cac1.pure.cloud',
  'apne2.pure.cloud',
  'euw2.pure.cloud',
  'aps1.pure.cloud',
  'use2.us-gov-pure.cloud',
  'sae1.pure.cloud',
]

export type GenesysChatFilters = Record<string, never>
export type GenesysChatAuth = {
  clientId: string
  clientSecret: string
  region:
    | 'mypurecloud.com'
    | 'mypurecloud.ie'
    | 'mypurecloud.com.au'
    | 'mypurecloud.jp'
    | 'mypurecloud.de'
    | 'usw2.pure.cloud'
    | 'cac1.pure.cloud'
    | 'apne2.pure.cloud'
    | 'euw2.pure.cloud'
    | 'aps1.pure.cloud'
    | 'use2.us-gov-pure.cloud'
    | 'sae1.pure.cloud'
}

export type ListGenesysResponse = {
  auth: GenesysChatAuth
  filters: GenesysChatFilters
  lastRunStarted: Date
  lastRunEnded: Date
  continuationToken: Date
  customFields: string[]
  friendlyName: string
  uid: string
  organizationUid: string
  isActive: boolean
}

export type AddGenesysPayload = {
  auth: GenesysChatAuth
  friendlyName: string
  continuationToken: Date
}
export type PatchGenesysPayload = Partial<{
  auth: Partial<{
    token: string
  }>
  friendlyName: string
  continuationToken: Date
  customFields: string[]
  filters: Partial<GenesysChatFilters>
}>

export const useTextImporter = <T>(
  urlKey: string,
): UseQueryResult<T[], ResponseError> =>
  useQuery({
    queryKey: ['importers', urlKey],
    queryFn: () =>
      request.get<ListGenesysResponse[]>(`importer/${urlKey}/list`),
  })

export const useAddTextImporter = <tAddPayload>(
  urlKey: string,
): UseMutationResult<unknown, ResponseError, tAddPayload, ResponseError> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['importers', urlKey],
    mutationFn: (payload: tAddPayload) =>
      request.post(`importer/${urlKey}/add`, { json: payload }),
    onSuccess: (importer) => {
      queryClient.setQueryData<unknown[]>(['importers', urlKey], (oldData) => {
        if (!oldData) {
          return [importer]
        }
        return [...oldData, importer]
      })
    },
  })
}

export const useUpdateTextImporter = <tPatchPayload>(
  urlKey: string,
): UseMutationResult<
  { uid: string },
  ResponseError,
  { uid: string; payload: tPatchPayload },
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['importers', urlKey],
    mutationFn: ({ uid, payload }) =>
      request.patch(`importer/${urlKey}/${uid}`, { json: payload }),
    onSuccess: (importer, { uid }) => {
      queryClient.setQueryData<{ uid: string }[]>(
        ['importers', urlKey],
        (oldData) => {
          if (!oldData) {
            return [importer]
          }
          return oldData.map((d) => (d.uid === uid ? { ...d, ...importer } : d))
        },
      )
    },
  })
}
export const useToggleTextImporter = (
  urlKey: string,
): UseMutationResult<
  { uid: string },
  ResponseError,
  { uid: string; action: 'start' | 'stop' },
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['importers', urlKey],
    mutationFn: ({ uid, action }) =>
      request.post(`importer/${urlKey}/${uid}/${action}`),
    onSuccess: (_, { uid, action }) => {
      queryClient.setQueryData<{ uid: string }[]>(
        ['importers', urlKey],
        (oldData) => {
          if (!oldData) {
            return []
          }
          return oldData.map((d) =>
            d.uid === uid ? { ...d, isActive: action === 'start' } : d,
          )
        },
      )
    },
  })
}
