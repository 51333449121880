import { Button, Card, useToast } from '@capturi/ui-components'
import {
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Input,
  Stack,
  Tooltip,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React, { useState } from 'react'
import isEqual from 'react-fast-compare'

import { useModal } from '@capturi/use-modal'
import { ChipsInput } from '../ChipsInput'
import CustomFieldSelector from '../CustomFieldSelector'
import CategorySelector from './CategorySelector'
import DirectionSelector from './DirectionSelector'
import FindTsControl from './FindTsControl'
import {
  EtrackDirections,
  ListEtrack1Response,
  PatchEtrack1Payload,
  useStartEtrack1Importer,
  useStopEtrack1Importer,
  useUpdateEtrack1Importer,
} from './useEtrack1Importer'

type Props = ListEtrack1Response

const Config: React.FC<Props> = (props) => {
  const { filters, isActive, uid } = props
  const { mutate, isPending } = useUpdateEtrack1Importer()
  const { mutate: start, isPending: isLoadingStart } = useStartEtrack1Importer()
  const { mutate: stop, isPending: isLoadingStop } = useStopEtrack1Importer()

  const [openFindTsControl] = useModal(FindTsControl)
  const [customFields, setCustomFields] = useState(props.customFields)
  const [tsControl, setTsControl] = useState(props.continuationToken)
  const toast = useToast()
  const [whitelistedCategoryIds, setWhitelistedCategoryIds] = useState<
    number[]
  >(filters.whitelistedCategoryIds)
  const [whitelistedDirections, setWhitelistedDirections] = useState<
    EtrackDirections[]
  >(filters.whitelistedDirections)

  const [blacklistedEmails, setBlacklistedEmails] = useState<string[]>(
    filters.blacklistedEmails,
  )
  const [blacklistedDomains, setBlacklistedDomains] = useState<string[]>(
    filters.blacklistedDomains,
  )

  const isDirty = !(
    isEqual(filters.whitelistedCategoryIds, whitelistedCategoryIds) &&
    isEqual(customFields, props.customFields) &&
    isEqual(tsControl, props.continuationToken) &&
    isEqual(whitelistedDirections, filters.whitelistedDirections) &&
    isEqual(blacklistedEmails, filters.blacklistedEmails) &&
    isEqual(blacklistedDomains, filters.blacklistedDomains)
  )

  const handleUpdate = (): void => {
    const payload: PatchEtrack1Payload = {
      continuationToken: tsControl,
      filters: {
        whitelistedCategoryIds,
        whitelistedDirections,
        blacklistedEmails,
        blacklistedDomains,
      },
      customFields,
    }

    mutate(
      { uid, payload },
      {
        onSuccess: () => {
          toast({
            title: t`eTRack1 importer configuration updated`,
            status: 'success',
          })
        },
        onError: (error) => {
          toast({
            title: t`Could not update eTrack1 importer configuration`,
            description: error.message,
            status: 'error',
          })
        },
      },
    )
  }

  return (
    <Card p="4" key={uid}>
      <Flex mb="8">
        <Heading>eTRack1 Importer Configuration</Heading>
      </Flex>
      <Grid gridTemplateColumns="1fr 1fr" gap="8">
        <Stack spacing="8">
          <FormControl>
            <FormLabel htmlFor="tsControl">
              <Trans>ts control</Trans>
            </FormLabel>
            <Input
              size="sm"
              type="number"
              onChange={(e) => setTsControl(e.currentTarget.valueAsNumber)}
              value={tsControl}
              isDisabled={isPending}
              onClick={() =>
                openFindTsControl({
                  integrationUid: uid,
                  onSubmit: setTsControl,
                })
              }
            />
          </FormControl>

          <CustomFieldSelector
            baseUrl="etrack1"
            isDisabled={isPending}
            onChange={setCustomFields}
            integrationUid={uid}
            initialCostumFields={customFields}
          />
        </Stack>
        <CategorySelector
          isDisabled={isPending}
          onChange={setWhitelistedCategoryIds}
          integrationUid={uid}
          initialCategories={whitelistedCategoryIds}
        />
        <DirectionSelector
          isDisabled={isPending}
          onChange={setWhitelistedDirections}
          integrationUid={uid}
          initialDirections={whitelistedDirections}
        />

        <FormControl>
          <FormLabel>
            <Trans>Blacklisted e-mails</Trans>
          </FormLabel>
          <ChipsInput
            onChange={setBlacklistedEmails}
            value={blacklistedEmails}
            placeholder={t`Enter e-mail ...`}
          />
        </FormControl>
        <FormControl>
          <FormLabel>
            <Trans>Blacklisted e-mail domains (capturi.com, example.com)</Trans>
          </FormLabel>
          <ChipsInput
            onChange={setBlacklistedDomains}
            value={blacklistedDomains}
            placeholder={t`Enter domain ...`}
          />
        </FormControl>
      </Grid>

      <Flex mt="4" justifyContent="space-between">
        <Button
          colorScheme={isActive ? 'red' : 'green'}
          isLoading={isLoadingStart || isLoadingStop}
          onClick={isActive ? () => stop(uid) : () => start(uid)}
        >
          {isActive ? t`Stop` : t`Start`}
        </Button>
        <Tooltip label={t`No changes to update`} isDisabled={isDirty}>
          <Button
            isDisabled={!isDirty}
            onClick={handleUpdate}
            primary
            isLoading={isPending}
          >
            <Trans>Update Configuration</Trans>
          </Button>
        </Tooltip>
      </Flex>
    </Card>
  )
}

export default Config
