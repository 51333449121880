import { Button, Card, useToast } from '@capturi/ui-components'
import {
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Input,
  Stack,
  Tooltip,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { lightFormat } from 'date-fns/fp'
import React, { useState } from 'react'
import isEqual from 'react-fast-compare'

import { ChipsInput } from '../ChipsInput'
import DirectionSelector from '../DirectionSelector'
import { useFilterInput } from '../useFilterInput'
import CustomFieldSelector from './CustomFieldsSelector'
import WhitelistedQueuesSelector from './WhitelistedQueuesSelector'
import {
  ListDixaResponse,
  PatchDixaPayload,
  useStartDixaImporter,
  useStopDixaImporter,
  useUpdateDixaImporter,
} from './useDixaImporter'

const formatDate = lightFormat("yyy-MM-dd'T'HH:mm")

type Props = ListDixaResponse

const directions = [
  { value: 'Inbound', label: 'Inbound' },
  { value: 'Outbound', label: 'Outbound' },
]

const Config: React.FC<Props> = (props) => {
  const { filters: initialFilters, isActive, uid } = props
  const { mutate, isPending } = useUpdateDixaImporter()
  const { mutate: start, isPending: isLoadingStart } = useStartDixaImporter()
  const { mutate: stop, isPending: isLoadingStop } = useStopDixaImporter()
  const [customFields, setCustomFields] = useState<string[]>(props.customFields)
  const [continuationToken, setContinuationToken] = useState<string>(
    formatDate(props.continuationToken),
  )

  const [filters, setFilters] = useFilterInput(initialFilters)

  const toast = useToast()

  const isDirty = !(
    isEqual(filters, initialFilters) &&
    isEqual(customFields, props.customFields) &&
    // it would be better to compare the date-objects, but the html date-picker doesn't have seconds
    isEqual(continuationToken, formatDate(props.continuationToken))
  )

  const handleUpdate = (): void => {
    const payload: PatchDixaPayload = {
      continuationToken: new Date(continuationToken),
      filters: filters,
      customFields: customFields,
    }

    mutate(
      { uid, payload },
      {
        onSuccess: () => {
          toast({
            title: t`Dixa importer configuration updated`,
            status: 'success',
          })
        },
        onError: (error) => {
          toast({
            title: t`Could not update Dixa importer configuration`,
            description: error.message,
            status: 'error',
          })
        },
      },
    )
  }

  return (
    <Card p="4" key={uid}>
      <Flex mb="8">
        <Heading>Dixa Importer Configuration</Heading>
      </Flex>
      <Grid gridTemplateColumns="1fr 1fr" gap="8">
        <Stack spacing="8">
          <FormControl>
            <FormLabel htmlFor="friendlyName">
              <Trans>Last imported ticket</Trans>
            </FormLabel>
            <Input
              size="sm"
              type="datetime-local"
              onChange={(e) => setContinuationToken(e.currentTarget.value)}
              value={continuationToken}
              isDisabled={isPending}
            />
          </FormControl>

          <CustomFieldSelector
            isDisabled={isPending}
            onChange={setCustomFields}
            integrationUid={uid}
            initialCostumFields={customFields}
          />
        </Stack>
        <WhitelistedQueuesSelector
          isDisabled={isPending}
          integrationUid={uid}
          onChange={setFilters.whitelistedQueueIds.onChange}
          initialQueueIds={setFilters.whitelistedQueueIds.value}
        />
        <DirectionSelector
          isDisabled={isPending}
          onChange={setFilters.whitelistedDirections.onChange}
          initialDirections={filters.whitelistedDirections}
          options={directions}
        />

        <FormControl>
          <FormLabel>
            <Trans>Blacklisted e-mails</Trans>
          </FormLabel>
          <ChipsInput
            {...setFilters.blacklistedSenderEmails}
            placeholder={t`Enter e-mail ...`}
          />
        </FormControl>
        <FormControl>
          <FormLabel>
            <Trans>Blacklisted e-mail domains (capturi.com, example.com)</Trans>
          </FormLabel>
          <ChipsInput
            {...setFilters.blacklistedSenderDomains}
            placeholder={t`Enter domain ...`}
          />
        </FormControl>
      </Grid>

      <Flex mt="4" justifyContent="space-between">
        <Button
          colorScheme={isActive ? 'red' : 'green'}
          isLoading={isLoadingStart || isLoadingStop}
          onClick={isActive ? () => stop(uid) : () => start(uid)}
        >
          {isActive ? t`Stop` : t`Start`}
        </Button>
        <Tooltip label={t`No changes to update`} isDisabled={isDirty}>
          <Button
            isDisabled={!isDirty || isLoadingStart || isLoadingStop}
            onClick={handleUpdate}
            primary
            isLoading={isPending}
          >
            <Trans>Update Configuration</Trans>
          </Button>
        </Tooltip>
      </Flex>
    </Card>
  )
}

export default Config
