import request, { ResponseError } from '@capturi/request'
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

const CACHE_KEY = ['importers', 'etrack1']

export type EtrackDirections =
  | 'InitialContact'
  | 'Inbound'
  | 'Outbound'
  | 'AutoReply'
  | 'CaseNote'

type Etrack1Filters = {
  whitelistedCategoryIds: number[]
  blacklistedCategoryIds: number[]
  blacklistedEmails: string[]
  whitelistedDirections: EtrackDirections[]
  blacklistedDomains: string[]
}

export type ListEtrack1Response = {
  auth: {
    username: string
    // password: string
    clientId: string
  }
  filters: Etrack1Filters
  lastRunStarted: Date
  lastRunEnded: Date
  continuationToken: number
  customFields: string[]
  friendlyName: string
  uid: string
  organizationUid: string
  isActive: boolean
}

export type AddEtrack1Payload = {
  auth: {
    username: string
    password: string
    clientId: string
  }
  friendlyName: string
  continuationToken: number
}
export type PatchEtrack1Payload = Partial<{
  auth: Partial<{
    username: string
    password: string
    clientId: string
  }>
  friendlyName: string
  continuationToken: number
  filters: Partial<Etrack1Filters>
  customFields: string[]
}>

export const useEtrack1Importer = (): UseQueryResult<
  ListEtrack1Response[],
  ResponseError
> =>
  useQuery({
    queryKey: CACHE_KEY,
    queryFn: () => request.get<ListEtrack1Response[]>('importer/etrack1/list'),
  })

export const useAddEtrack1Importer = (): UseMutationResult<
  ListEtrack1Response,
  ResponseError,
  AddEtrack1Payload,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: CACHE_KEY,
    mutationFn: (model: AddEtrack1Payload) =>
      request.post('importer/etrack1/add', { json: model }),
    onSuccess: (importer) => {
      queryClient.setQueryData<ListEtrack1Response[]>(CACHE_KEY, (oldData) => {
        if (!oldData) {
          return [importer]
        }
        return [...oldData, importer]
      })
    },
  })
}

type UpdateEtrack1Importer = { uid: string; payload: PatchEtrack1Payload }
export const useUpdateEtrack1Importer = (): UseMutationResult<
  ListEtrack1Response,
  ResponseError,
  UpdateEtrack1Importer,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: CACHE_KEY,
    mutationFn: ({ uid, payload }) =>
      request.patch(`importer/etrack1/${uid}`, { json: payload }),
    onSuccess: (importer, { uid }) => {
      queryClient.setQueryData<ListEtrack1Response[]>(CACHE_KEY, (oldData) => {
        if (!oldData) {
          return [importer]
        }
        return oldData.map((d) => (d.uid === uid ? { ...d, ...importer } : d))
      })
    },
  })
}
export const useStartEtrack1Importer = (): UseMutationResult<
  ListEtrack1Response,
  ResponseError,
  string,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: CACHE_KEY,
    mutationFn: (uid: string) => request.post(`importer/etrack1/${uid}/start`),
    onSuccess: (_, uid) => {
      queryClient.setQueryData<ListEtrack1Response[]>(CACHE_KEY, (oldData) => {
        if (!oldData) {
          return []
        }
        return oldData.map((d) =>
          d.uid === uid ? { ...d, isActive: true } : d,
        )
      })
    },
  })
}
export const useStopEtrack1Importer = (): UseMutationResult<
  ListEtrack1Response,
  ResponseError,
  string,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: CACHE_KEY,
    mutationFn: (uid: string) => request.post(`importer/etrack1/${uid}/stop`),
    onSuccess: (_, uid) => {
      queryClient.setQueryData<ListEtrack1Response[]>(CACHE_KEY, (oldData) => {
        if (!oldData) {
          return []
        }
        return oldData.map((d) =>
          d.uid === uid ? { ...d, isActive: false } : d,
        )
      })
    },
  })
}
