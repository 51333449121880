import React from 'react'

import Config from './Config'
import { useEtrack1Importer } from './useEtrack1Importer'

const Etrack1: React.FC = () => {
  const { data = [] } = useEtrack1Importer()

  return data.map((d) => <Config key={d.uid} {...d} />)
}

export default Etrack1
