import { IconButton, Td, Tr, useToast } from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import React from 'react'
import { MdCopyAll } from 'react-icons/md'
import { useCopyToClipboard } from 'react-use'
import { TokenInfo } from '../../Api/ApiToken/models/ApiToken'

const date: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
}

const TokenTableRow: React.FC<TokenInfo> = ({
  name,
  expiry,
  issuedAt,
  token,
}) => {
  const [, copy] = useCopyToClipboard()
  const toast = useToast()
  return (
    <Tr>
      <Td>{name}</Td>
      <Td>{i18n.date(issuedAt, date)}</Td>
      <Td>{i18n.date(expiry, date)}</Td>
      <Td w="5%">
        <IconButton
          size="xs"
          variant="ghost"
          icon={<MdCopyAll />}
          aria-label={t`Copy to clipboard`}
          onClick={(): void => {
            copy(token)
            toast({
              title: t`Copied to clipboard`,
            })
          }}
        />
      </Td>
    </Tr>
  )
}

export default TokenTableRow
