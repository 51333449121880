import React from 'react'

import { useTextImporter } from '../useTextImporter'
import Config from './Config'
import { type BoostAiResponse, URL_KEY } from './types'

const BoostAi: React.FC = () => {
  const { data = [] } = useTextImporter<BoostAiResponse>(URL_KEY)

  return data.map((d) => <Config key={d.uid} {...d} />)
}

export default BoostAi
