import { BaseModalComponentProps } from '@capturi/use-modal'
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React, { useState } from 'react'
import { useCreateApiToken } from '../../Api/ApiToken/useApiToken'

type Props = {} & BaseModalComponentProps

const ApiTokenModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const [name, setName] = useState<string>('')
  const { mutate: createApiToken, isPending } = useCreateApiToken()
  const toast = useToast()

  const onSubmit = (name: string): void => {
    createApiToken(name, {
      onSuccess: () => {
        toast({
          status: 'success',
          title: t`Successfully created API token`,
        })
        onClose()
      },
      onError: (error) => {
        toast({
          status: 'error',
          title: t`Could not create API token. Try again.`,
          description: error.message,
        })
      },
    })
  }
  return (
    <Modal isOpen={isOpen ?? false} onClose={onClose}>
      <ModalOverlay>
        <ModalContent>
          <Flex direction="column">
            <ModalHeader>
              <Trans>Create token</Trans>
            </ModalHeader>
          </Flex>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                key="name"
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <HStack spacing={4} justify="flex-end">
              <Button isLoading={isPending} onClick={onClose}>
                <Trans>Cancel</Trans>
              </Button>
              <Button
                colorScheme="primary"
                isLoading={isPending}
                mr={3}
                onClick={() => onSubmit(name)}
              >
                <Trans>Create</Trans>
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

export default ApiTokenModal
