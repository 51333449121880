import { Button, Card, useToast } from '@capturi/ui-components'
import {
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Input,
  Switch,
  Tooltip,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { lightFormat } from 'date-fns/fp'
import React, { useState } from 'react'
import isEqual from 'react-fast-compare'

import { ChipsInput } from '../ChipsInput'
import CustomFieldSelector from '../CustomFieldSelector'
import DirectionSelector from '../DirectionSelector'
import { useFilterInput } from '../useFilterInput'
import {
  ListFocalscopeResponse,
  PatchFocalscopePayload,
  useStartFocalscopeImporter,
  useStopFocalscopeImporter,
  useUpdateFocalscopeImporter,
} from './useFocalscopeImporter'

const formatDate = lightFormat("yyy-MM-dd'T'HH:mm")

type Props = ListFocalscopeResponse

const Config: React.FC<Props> = (props) => {
  const { isActive, uid, filters: initialFilters, options } = props
  const { mutate, isPending } = useUpdateFocalscopeImporter()
  const { mutate: start, isPending: isLoadingStart } =
    useStartFocalscopeImporter()

  const { mutate: stop, isPending: isLoadingStop } = useStopFocalscopeImporter()

  const [mostRecentTicket, setMostRecentTicket] = useState<string>(
    formatDate(props.continuationToken),
  )
  const [customFields, setCustomFields] = useState(props.customFields)

  const [organizationDomains, setOrganizationDomains] = useState<string[]>(
    options.organizationDomains,
  )
  const [useNewAgentParser, setUseNewAgentParser] = useState<boolean>(
    options.useNewAgentParser,
  )

  const toast = useToast()

  const [filters, setFilters] = useFilterInput(initialFilters)

  const isDirty = !(
    // it would be better to compare the date-objects, but the html date-picker doesn't have seconds
    (
      isEqual(mostRecentTicket, formatDate(props.continuationToken)) &&
      isEqual(customFields, props.customFields) &&
      isEqual(filters, initialFilters) &&
      isEqual(useNewAgentParser, options.useNewAgentParser)
    )
  )

  const handleUpdate = (): void => {
    const payload: PatchFocalscopePayload = {
      continuationToken: new Date(mostRecentTicket),
      customFields,
      options: {
        organizationDomains,
        useNewAgentParser,
      },
      filters: filters,
    }

    mutate(
      { uid, payload },
      {
        onSuccess: () => {
          toast({
            title: t`Focalscope importer configuration updated`,
            status: 'success',
          })
        },
        onError: (error) => {
          toast({
            title: t`Could not update Focalscope importer configuration`,
            description: error.message,
            status: 'error',
          })
        },
      },
    )
  }

  return (
    <Card p="4" key={uid}>
      <Flex mb="8">
        <Heading>Focalscope Importer Configuration</Heading>
      </Flex>
      <Grid gridTemplateColumns="1fr 1fr" gap="8">
        <FormControl>
          <FormLabel htmlFor="friendlyName">
            <Trans>Last imported ticket</Trans>
          </FormLabel>
          <Input
            size="sm"
            type="datetime-local"
            onChange={(e) => setMostRecentTicket(e.currentTarget.value)}
            value={mostRecentTicket}
            isDisabled={isPending}
          />
        </FormControl>

        <CustomFieldSelector
          baseUrl="focalscope"
          isDisabled={isPending}
          onChange={setCustomFields}
          integrationUid={uid}
          initialCostumFields={customFields}
        />

        <DirectionSelector
          isDisabled={isPending}
          onChange={setFilters.whitelistedDirections.onChange}
          initialDirections={setFilters.whitelistedDirections.value}
        />

        <FormControl display="flex" alignItems="center">
          <Switch
            color="primary"
            isChecked={useNewAgentParser}
            onChange={(e) => setUseNewAgentParser(e.currentTarget.checked)}
            mr={2}
          />
          <FormLabel m={0}>
            <Trans>Enable new Agent parser</Trans>
          </FormLabel>
        </FormControl>
        <FormControl>
          <FormLabel>
            <Trans>Blacklisted e-mails</Trans>
          </FormLabel>
          <ChipsInput
            {...setFilters.blacklistedEmails}
            placeholder={t`Enter e-mail ...`}
          />
        </FormControl>
        <FormControl>
          <FormLabel>
            <Trans>Blacklisted e-mail domains (capturi.com, example.com)</Trans>
          </FormLabel>
          <ChipsInput
            {...setFilters.blacklistedDomains}
            placeholder={t`Enter domain ...`}
          />
        </FormControl>
        <FormControl>
          <FormLabel>
            <Trans>Whitelisted Folders</Trans>
          </FormLabel>
          <ChipsInput
            splitOnWhiteSpace={false}
            {...setFilters.whitelistedFolders}
            placeholder={t`Enter full folder name ...`}
          />
        </FormControl>
        <FormControl>
          <FormLabel>
            <Trans>Blacklisted Folders</Trans>
          </FormLabel>
          <ChipsInput
            splitOnWhiteSpace={false}
            {...setFilters.blacklistedFolders}
            placeholder={t`Enter full folder name ...`}
          />
        </FormControl>
        <FormControl>
          <FormLabel>
            <Trans>
              Organization Domains, the domains used for answering tickets
              (capturi.com, example.com)
            </Trans>
          </FormLabel>
          <ChipsInput
            onChange={setOrganizationDomains}
            value={organizationDomains}
            placeholder={t`Enter domain ...`}
          />
        </FormControl>
      </Grid>

      <Flex mt="4" justifyContent="space-between">
        <Button
          colorScheme={isActive ? 'red' : 'green'}
          isLoading={isLoadingStart || isLoadingStop}
          onClick={isActive ? () => stop(uid) : () => start(uid)}
        >
          {isActive ? t`Stop` : t`Start`}
        </Button>
        <Tooltip label={t`No changes to update`} isDisabled={isDirty}>
          <Button
            isDisabled={!isDirty || isLoadingStart || isLoadingStop}
            onClick={handleUpdate}
            primary
            isLoading={isPending}
          >
            <Trans>Update Configuration</Trans>
          </Button>
        </Tooltip>
      </Flex>
    </Card>
  )
}

export default Config
