import { BaseModalComponentProps } from '@capturi/use-modal'
import {
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { endOfDay, startOfDay, subMonths } from 'date-fns'
import React, { useState } from 'react'
import { WebhookEventFilter } from '../../Api/Webhooks/useWebhooks'
import WebhookLogTable from '../WebhookLogTable'

type Props = {} & BaseModalComponentProps

const WebhookLogModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const [filter, setFilter] = useState<WebhookEventFilter>({
    from: startOfDay(subMonths(new Date(), 1)),
    to: endOfDay(new Date()),
    event: 'ConversationSummaryUpdated',
    lastOutcome: 'Success',
  })
  return (
    <Modal
      size="6xl"
      isOpen={isOpen ?? false}
      onClose={onClose}
      blockScrollOnMount={false}
    >
      <ModalOverlay>
        <ModalContent>
          <Flex direction="column">
            <ModalHeader borderBottom="1px solid" borderColor="gray.200">
              <Trans>Webhook log</Trans>
            </ModalHeader>
          </Flex>
          <ModalCloseButton size="lg" />
          <ModalBody maxH="700px" overflowY="auto">
            <WebhookLogTable filter={filter} onSetFilter={setFilter} />
          </ModalBody>
          <ModalFooter>
            <HStack spacing={4} justify="flex-end">
              <Button onClick={onClose}>
                <Trans>Close</Trans>
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

export default WebhookLogModal
