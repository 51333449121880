import React from 'react'

import { TeamsApiProvider } from '../../hooks/useTeamsApi'
import Teams from './Teams'

const TeamsPage: React.FC = () => {
  return (
    <TeamsApiProvider>
      <Teams />
    </TeamsApiProvider>
  )
}

export default TeamsPage
