import { SectionHeading } from '@capturi/ui-components'
import { Box, Flex, Icon, Spacer, Tooltip, chakra } from '@chakra-ui/react'
import React from 'react'
import { IconType } from 'react-icons'

export const TableWrapperHeader: React.FC<{
  title?: string
  tooltip?: string
  icon?: IconType
  children?: React.ReactNode
}> = ({ title, tooltip, icon, children }) => {
  return (
    <Flex
      py={2}
      px={4}
      alignItems="center"
      justifyContent="space-between"
      h={12}
      gap={2}
    >
      {title && <SectionHeading m={0}>{title}</SectionHeading>}
      <Tooltip hasArrow label={tooltip} placement="top">
        <chakra.span display="inherit">
          <Icon as={icon} />
        </chakra.span>
      </Tooltip>
      <Spacer />
      {children}
    </Flex>
  )
}

export const TableWrapper: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Box
      border="1px solid"
      borderColor="gray.300"
      borderRadius="md"
      minW="fit-content"
    >
      {children}
    </Box>
  )
}
