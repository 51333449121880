import { getErrorObject } from '@capturi/request'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import { Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import noop from 'lodash/noop'
import React from 'react'

import { useDeleteSummaryWidgetConfig } from '../../Api/SummaryWidget/useSummaryWidgetConfig'

type Props = { name: string; uid: string } & BaseModalComponentProps

const DeleteSummaryWidgetModal: React.FC<Props> = ({ onClose, name, uid }) => {
  const { mutate: deleteConfig, isPending } = useDeleteSummaryWidgetConfig()
  const toast = useToast()

  const handleDelete = (): void => {
    deleteConfig(uid, {
      onError: (error) => {
        const { message, name } = getErrorObject(error)
        toast({ title: name, description: message, status: 'error' })
      },
      onSuccess: () => {
        toast({ title: 'Widget deleted', status: 'success' })
        onClose()
      },
    })
  }

  return (
    <Modal isOpen onClose={isPending ? noop : onClose}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>
            <Trans>Delete?</Trans>
          </ModalHeader>

          <ModalCloseButton isDisabled={isPending} />
          <ModalBody>
            <Text>
              <Trans>Are you sure you want to delete:</Trans>
            </Text>
            <Text fontWeight="bold">{name}</Text>
          </ModalBody>
          <ModalFooter justifyContent="flex-end">
            <Button mr="2" onClick={onClose} isDisabled={isPending}>
              <Trans>Cancel</Trans>
            </Button>
            <Button
              colorScheme="red"
              onClick={handleDelete}
              isLoading={isPending}
            >
              <Trans>Delete</Trans>
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

export default DeleteSummaryWidgetModal
