import { useCurrentUser } from '@capturi/core'
import { Button, Card, useToast } from '@capturi/ui-components'
import {
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Input,
  Tooltip,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { lightFormat } from 'date-fns/fp'
import React, { useState } from 'react'
import isEqual from 'react-fast-compare'
import { ChipsInput } from '../ChipsInput'
import DirectionSelector from '../DirectionSelector'
import { useFilterInput } from '../useFilterInput'
import BackSync from './BackSync'
import {
  type GenesysFilters,
  type GenesysOptions,
  type ListGenesysResponse,
  type PatchGenesysPayload,
  useStartGenesysImporter,
  useStopGenesysImporter,
  useUpdateGenesysImporter,
} from './useGenesysImporter'

const formatDate = lightFormat("yyy-MM-dd'T'HH:mm")

type Props = ListGenesysResponse

const DEFAULT_FILTERS: GenesysFilters = {
  blacklistedEmails: [],
  blacklistedDomains: [],
  whitelistedDirections: [],
  blacklistedInboxes: [],
  whitelistedInboxes: [],
}
const DEFAULT_OPTIONS: GenesysOptions = {
  cutOffWords: [],
}

const Config: React.FC<Props> = (props) => {
  const {
    isActive,
    uid,
    filters: initialFilters,
    options: initialOptions,
    friendlyName,
  } = props
  const { hasSuperPowers } = useCurrentUser()
  const { mutate, isPending } = useUpdateGenesysImporter()
  const { mutate: start, isPending: isLoadingStart } = useStartGenesysImporter()

  const { mutate: stop, isPending: isLoadingStop } = useStopGenesysImporter()

  const [mostRecentTicket, setMostRecentTicket] = useState<string>(
    formatDate(props.continuationToken),
  )

  const toast = useToast()
  const [filters, setFilters] = useFilterInput({
    ...DEFAULT_FILTERS,
    ...initialFilters,
  })
  const [options, setOptions] = useFilterInput({
    ...DEFAULT_OPTIONS,
    ...initialOptions,
  })

  const isDirty = !(
    // it would be better to compare the date-objects, but the html date-picker doesn't have seconds
    (
      isEqual(mostRecentTicket, formatDate(props.continuationToken)) &&
      isEqual(filters, initialFilters) &&
      isEqual(options, initialOptions)
    )
  )

  const handleUpdate = (): void => {
    const payload: PatchGenesysPayload = {
      continuationToken: new Date(mostRecentTicket),
      filters,
      options,
    }

    mutate(
      { uid, payload },
      {
        onSuccess: () => {
          toast({
            title: t`Genesys importer configuration updated`,
            status: 'success',
          })
        },
        onError: (error) => {
          toast({
            title: t`Could not update Genesys importer configuration`,
            description: error.message,
            status: 'error',
          })
        },
      },
    )
  }

  return (
    <div>
      <Card p="4" key={uid}>
        <Flex mb="8">
          <Heading>Genesys Importer Configuration</Heading>
        </Flex>
        <Grid gridTemplateColumns="1fr 1fr" gap="8">
          <FormControl>
            <FormLabel htmlFor="Last imported ticket">
              <Trans>Last imported ticket</Trans>
            </FormLabel>
            <Input
              size="sm"
              type="datetime-local"
              onChange={(e) => setMostRecentTicket(e.currentTarget.value)}
              value={mostRecentTicket}
              isDisabled={isPending}
            />
          </FormControl>
          <DirectionSelector
            isDisabled={isPending}
            onChange={setFilters.whitelistedDirections.onChange}
            initialDirections={setFilters.whitelistedDirections.value}
          />
          <FormControl>
            <FormLabel>
              <Trans>Email cut off words</Trans>
            </FormLabel>
            <ChipsInput
              {...setOptions.cutOffWords}
              placeholder={t`Enter word or sentences ...`}
              splitOnWhiteSpace={false}
              splitOnComma={false}
            />
          </FormControl>

          <FormControl>
            <FormLabel>
              <Trans>Blacklisted e-mails</Trans>
            </FormLabel>
            <ChipsInput
              {...setFilters.blacklistedEmails}
              placeholder={t`Enter e-mail ...`}
            />
          </FormControl>
          <FormControl>
            <FormLabel>
              <Trans>
                Blacklisted e-mail domains (capturi.com, example.com)
              </Trans>
            </FormLabel>
            <ChipsInput
              {...setFilters.blacklistedDomains}
              placeholder={t`Enter domain ...`}
            />
          </FormControl>
          <FormControl>
            <FormLabel>
              <Trans>Whitelisted Inboxes</Trans>
            </FormLabel>
            <ChipsInput
              {...setFilters.whitelistedInboxes}
              placeholder={t`Enter Inbox ...`}
              splitOnWhiteSpace={false}
            />
          </FormControl>
          <FormControl>
            <FormLabel>
              <Trans>Blacklisted Inboxes</Trans>
            </FormLabel>
            <ChipsInput
              {...setFilters.blacklistedInboxes}
              placeholder={t`Enter Inbox ...`}
              splitOnWhiteSpace={false}
            />
          </FormControl>
        </Grid>

        <Flex mt="4" justifyContent="space-between">
          <Button
            colorScheme={isActive ? 'red' : 'green'}
            isLoading={isLoadingStart || isLoadingStop}
            onClick={isActive ? () => stop(uid) : () => start(uid)}
          >
            {isActive ? t`Stop` : t`Start`}
          </Button>
          <Tooltip label={t`No changes to update`} isDisabled={isDirty}>
            <Button
              isDisabled={!isDirty || isLoadingStart || isLoadingStop}
              onClick={handleUpdate}
              primary
              isLoading={isPending}
            >
              <Trans>Update Configuration</Trans>
            </Button>
          </Tooltip>
        </Flex>
      </Card>

      {hasSuperPowers && <BackSync uid={uid} friendlyName={friendlyName} />}
    </div>
  )
}

export default Config
