export const URL_KEY = 'boostai'

export type BoostAiFilters = Record<string, never>
export type BoostAiAuth = {
  site: string
  bearerToken: string
}

export type BoostAiResponse = {
  auth: BoostAiAuth
  filters: BoostAiFilters
  lastRunStarted: Date
  lastRunEnded: Date
  continuationToken: Date
  customFields: string[]
  friendlyName: string
  uid: string
  organizationUid: string
  isActive: boolean
}

export type BoostAiAddPayload = {
  auth: BoostAiAuth
  friendlyName: string
  continuationToken: Date
  customFields: string[]
  filters: Partial<BoostAiFilters>
}
export type BoostAiPatchPayload = Partial<{
  auth: Partial<{
    token: string
  }>
  friendlyName: string
  continuationToken: Date
  customFields: string[]
  filters: Partial<BoostAiFilters>
}>
