import request, { ResponseError } from '@capturi/request'
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

const CACHE_KEY = ['importers', 'Mock']

export type MockFilters = object

export type ListMockResponse = {
  auth: Record<string, never>
  filters: MockFilters
  lastRunStarted: Date
  lastRunEnded: Date
  continuationToken: Date
  customFields: string[]
  friendlyName: string
  uid: string
  organizationUid: string
  isActive: boolean
}

export type AddMockPayload = {
  auth: object
  friendlyName: string
  continuationToken: Date
}
export type PatchMockPayload = Partial<{
  auth: Partial<{
    token: string
  }>
  friendlyName: string
  continuationToken: Date
  customFields: string[]
  filters: Partial<MockFilters>
}>

export const useMockImporter = (): UseQueryResult<
  ListMockResponse[],
  ResponseError
> =>
  useQuery({
    queryKey: CACHE_KEY,
    queryFn: () => request.get<ListMockResponse[]>('importer/mock/list'),
  })

export const useAddMockImporter = (): UseMutationResult<
  ListMockResponse,
  ResponseError,
  AddMockPayload,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: CACHE_KEY,
    mutationFn: (payload: AddMockPayload) =>
      request.post('importer/mock/add', { json: payload }),
    onSuccess: (importer) => {
      queryClient.setQueryData<ListMockResponse[]>(CACHE_KEY, (oldData) => {
        if (!oldData) {
          return [importer]
        }
        return [...oldData, importer]
      })
    },
  })
}

type UpdateImporter = { uid: string; payload: PatchMockPayload }
export const useUpdateMockImporter = (): UseMutationResult<
  ListMockResponse,
  ResponseError,
  UpdateImporter,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: CACHE_KEY,
    mutationFn: ({ uid, payload }) =>
      request.patch(`importer/mock/${uid}`, { json: payload }),
    onSuccess: (importer, { uid }) => {
      queryClient.setQueryData<ListMockResponse[]>(CACHE_KEY, (oldData) => {
        if (!oldData) {
          return [importer]
        }

        return oldData.map((d) => (d.uid === uid ? { ...d, ...importer } : d))
      })
    },
  })
}
export const useStartMockImporter = (): UseMutationResult<
  ListMockResponse,
  ResponseError,
  string,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: CACHE_KEY,
    mutationFn: (uid: string) => request.post(`importer/mock/${uid}/start`),
    onSuccess: (_, uid) => {
      queryClient.setQueryData<ListMockResponse[]>(CACHE_KEY, (oldData) => {
        if (!oldData) {
          return []
        }
        return oldData.map((d) =>
          d.uid === uid ? { ...d, isActive: true } : d,
        )
      })
    },
  })
}
export const useStopMockImporter = (): UseMutationResult<
  ListMockResponse,
  ResponseError,
  string,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: CACHE_KEY,
    mutationFn: (uid: string) => request.post(`importer/mock/${uid}/stop`),
    onSuccess: (_, uid) => {
      queryClient.setQueryData<ListMockResponse[]>(CACHE_KEY, (oldData) => {
        if (!oldData) {
          return []
        }
        return oldData.map((d) =>
          d.uid === uid ? { ...d, isActive: false } : d,
        )
      })
    },
  })
}
