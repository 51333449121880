import { useCurrentUser } from '@capturi/core'
import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React, { ReactElement, useState } from 'react'
import { RiArrowRightSLine } from 'react-icons/ri'
import AddBoostAi from './boostAi/AddBoostAi'
import AddDixa from './dixa/AddDixa'
import AddEtrack1 from './etrack1/AddEtrack1'
import AddFocalscope from './focalscope/AddFocalscope'
import AddFocalscopeSftp from './focalscopeSftp/AddFocalscopeSftp'
import AddGenesys from './genesys/AddGenesys'
import AddGenesysChat from './genesysChat/AddGenesysChat'
import { getTextColor } from './getTextColor'
import AddMock from './mock/AddMock'
import AddZendesk from './zendesk/AddZendesk'

type Props = { onClose: () => void }

type Integrations =
  | 'zendesk'
  | 'etrack1'
  | 'focalscope'
  | 'focalscopeSftp'
  | 'dixa'
  | 'mock'
  | 'genesys'
  | 'genesysChat'
  | 'boostAi'

type Integration = {
  name: string
  key: Integrations
  color: string
}

const textIntegrations: Integration[] = [
  {
    color: '#649a3d',
    key: 'etrack1',
    name: 'eTrack1',
  },
  {
    color: '#16140d',
    key: 'zendesk',
    name: 'Zendesk',
  },
  {
    color: '#3a8481',
    key: 'focalscope',
    name: 'FocalScope',
  },
  {
    color: '#3a8481',
    key: 'focalscopeSftp',
    name: 'FocalScope Sftp',
  },
  {
    color: '#5644d8',
    key: 'dixa',
    name: 'Dixa',
  },
  {
    color: '#FF4F1F',
    key: 'genesys',
    name: 'Genesys',
  },
  {
    color: '#FF4F1F',
    key: 'genesysChat',
    name: 'Genesys Chat',
  },
  {
    color: '#59195D',
    key: 'boostAi',
    name: 'BoostAi',
  },
]

const AddImporterModal: React.FC<Props> = ({ onClose }) => {
  const [add, setAdd] = useState<Integrations | null>(null)
  const { hasSuperPowers } = useCurrentUser()

  const renderIntegrationButton = ({
    name,
    key,
    color,
  }: Integration): ReactElement => {
    return (
      <Flex
        justifyContent="space-between"
        alignItems="center"
        as="button"
        p="2"
        _hover={{
          bg: 'primary.50',
        }}
        width="100%"
        borderColor="border.light"
        borderBottomWidth="1px"
        key={key}
        onClick={() => setAdd(key)}
      >
        <Flex alignItems="center">
          <Box
            w="28px"
            h="28px"
            fontSize="1.2rem"
            bg={color}
            mr="4"
            borderRadius="4"
            justifyContent="center"
            alignItems="center"
            color={getTextColor(color)}
          >
            {name[0]}
          </Box>
          {name}
        </Flex>
        <RiArrowRightSLine color="gray" size="25" />
      </Flex>
    )
  }

  if (add === 'zendesk') return <AddZendesk onClose={onClose} />
  if (add === 'etrack1') return <AddEtrack1 onClose={onClose} />
  if (add === 'focalscope') return <AddFocalscope onClose={onClose} />
  if (add === 'focalscopeSftp') return <AddFocalscopeSftp onClose={onClose} />
  if (add === 'dixa') return <AddDixa onClose={onClose} />
  if (add === 'genesys') return <AddGenesys onClose={onClose} />
  if (add === 'genesysChat') return <AddGenesysChat onClose={onClose} />
  if (add === 'mock') return <AddMock onClose={onClose} />
  if (add === 'boostAi') return <AddBoostAi onClose={onClose} />

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay>
        <ModalContent>
          <Flex direction="column">
            <ModalHeader>
              <Trans>Add Importer</Trans>
            </ModalHeader>
          </Flex>
          <ModalCloseButton />
          <ModalBody>
            {textIntegrations.map(renderIntegrationButton)}

            {hasSuperPowers &&
              renderIntegrationButton({
                color: '#216D97',
                key: 'mock',
                name: 'Mock integration',
              })}
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

export default AddImporterModal
