import request from '@capturi/request'
import { useToast } from '@capturi/ui-components'
import {
  ActionMeta,
  OnChangeValue,
  Select,
  SelectOption,
} from '@capturi/ui-select'
import { Flex, FormControl, FormLabel, IconButton } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { MdCopyAll } from 'react-icons/md'

type Props = {
  integrationUid: string
  initialCostumFields: string[]
  onChange: (fields: string[]) => void
  isDisabled: boolean
  baseUrl: string
}

const CustomFieldSelector: React.FC<Props> = ({
  baseUrl,
  integrationUid,
  onChange,
  initialCostumFields = [],
  isDisabled,
}) => {
  const toast = useToast()

  const { data: customProps = [] } = useQuery<string[]>({
    queryFn: () =>
      request.get(`importer/${baseUrl}/${integrationUid}/custom-fields`),
    queryKey: ['importer', 'customFields', baseUrl, integrationUid],
    enabled: !!integrationUid,
  })

  const options = customProps.map((c) => ({ value: c, label: c }))

  const initialValue = initialCostumFields.map((c) => ({
    value: c,
    label: c,
  }))

  const handleChange = (
    values: OnChangeValue<SelectOption, boolean>,
    action: ActionMeta<SelectOption>,
  ): void => {
    if (
      ['select-option', 'remove-value', 'deselect-option'].includes(
        action.action,
      ) &&
      Array.isArray(values)
    ) {
      onChange(values.map((x) => x.value))
    }
  }
  const handleCopy = (): void => {
    navigator.clipboard.writeText(customProps.join('\n'))
    toast({ status: 'info', title: t`Copied custom fields list to clipboard` })
  }

  return (
    <FormControl>
      <Flex justifyContent="space-between">
        <FormLabel>
          <Trans>Custom Fields (max 10)</Trans>
        </FormLabel>
        <IconButton
          variant="ghost"
          icon={<MdCopyAll />}
          onClick={handleCopy}
          aria-label="copy list content"
        />
      </Flex>
      <Select
        isOptionDisabled={() => initialCostumFields.length >= 10}
        isDisabled={isDisabled}
        defaultValue={initialValue}
        isMulti
        options={options}
        placeholder={t`Select custom fields ...`}
        onChange={handleChange}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        hideSelectedOptions={false}
        backspaceRemovesValue
        isClearable={false}
        autoFocus={false}
        minMenuHeight={100}
        maxMenuHeight={612}
      />
    </FormControl>
  )
}

export default CustomFieldSelector
