import React from 'react'

import Config from './Config'
import { useFocalscopeImporter } from './useFocalscopeImporter'

const Focalscope: React.FC = () => {
  const { data = [] } = useFocalscopeImporter()

  return data.map((d) => <Config key={d.uid} {...d} />)
}

export default Focalscope
