import request, { ResponseError } from '@capturi/request'
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { Direction } from '../DirectionSelector'

const CACHE_KEY = ['importers', 'Genesys']

export const regions = [
  'mypurecloud.com',
  'mypurecloud.ie',
  'mypurecloud.com.au',
  'mypurecloud.jp',
  'mypurecloud.de',
  'usw2.pure.cloud',
  'cac1.pure.cloud',
  'apne2.pure.cloud',
  'euw2.pure.cloud',
  'aps1.pure.cloud',
  'use2.us-gov-pure.cloud',
  'sae1.pure.cloud',
]

export type GenesysFilters = {
  blacklistedEmails: string[]
  blacklistedDomains: string[]
  whitelistedInboxes: string[]
  blacklistedInboxes: string[]
  whitelistedDirections: Direction[]
}
export type GenesysOptions = { cutOffWords: string[] }
export type GenesysAuth = {
  clientId: string
  clientSecret: string
  region:
    | 'mypurecloud.com'
    | 'mypurecloud.ie'
    | 'mypurecloud.com.au'
    | 'mypurecloud.jp'
    | 'mypurecloud.de'
    | 'usw2.pure.cloud'
    | 'cac1.pure.cloud'
    | 'apne2.pure.cloud'
    | 'euw2.pure.cloud'
    | 'aps1.pure.cloud'
    | 'use2.us-gov-pure.cloud'
    | 'sae1.pure.cloud'
}

export type ListGenesysResponse = {
  auth: GenesysAuth
  filters: GenesysFilters
  lastRunStarted: Date
  lastRunEnded: Date
  continuationToken: Date
  customFields: string[]
  friendlyName: string
  uid: string
  organizationUid: string
  isActive: boolean
  options: GenesysOptions
}

export type AddGenesysPayload = {
  auth: GenesysAuth
  friendlyName: string
  continuationToken: Date
  filters: GenesysFilters
  options: GenesysOptions
}
export type PatchGenesysPayload = Partial<{
  auth: Partial<{
    token: string
  }>
  friendlyName: string
  continuationToken: Date
  customFields: string[]
  filters: Partial<GenesysFilters>
  options: Partial<GenesysOptions>
}>

export const useGenesysImporter = (): UseQueryResult<
  ListGenesysResponse[],
  ResponseError
> =>
  useQuery({
    queryKey: CACHE_KEY,
    queryFn: () => request.get<ListGenesysResponse[]>('importer/genesys/list'),
  })

export const useAddGenesysImporter = (): UseMutationResult<
  ListGenesysResponse,
  ResponseError,
  AddGenesysPayload,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: CACHE_KEY,
    mutationFn: (payload: AddGenesysPayload) =>
      request.post('importer/genesys/add', { json: payload }),
    onSuccess: (importer) => {
      queryClient.setQueryData<ListGenesysResponse[]>(CACHE_KEY, (oldData) => {
        if (!oldData) {
          return [importer]
        }
        return [...oldData, importer]
      })
    },
  })
}

type UpdateImporter = { uid: string; payload: PatchGenesysPayload }
export const useUpdateGenesysImporter = (): UseMutationResult<
  ListGenesysResponse,
  ResponseError,
  UpdateImporter,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: CACHE_KEY,
    mutationFn: ({ uid, payload }) =>
      request.patch(`importer/genesys/${uid}`, { json: payload }),
    onSuccess: (importer, { uid }) => {
      queryClient.setQueryData<ListGenesysResponse[]>(CACHE_KEY, (oldData) => {
        if (!oldData) {
          return [importer]
        }

        return oldData.map((d) => (d.uid === uid ? { ...d, ...importer } : d))
      })
    },
  })
}
export const useStartGenesysImporter = (): UseMutationResult<
  ListGenesysResponse,
  ResponseError,
  string,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: CACHE_KEY,
    mutationFn: (uid: string) => request.post(`importer/genesys/${uid}/start`),
    onSuccess: (_, uid) => {
      queryClient.setQueryData<ListGenesysResponse[]>(CACHE_KEY, (oldData) => {
        if (!oldData) {
          return []
        }
        return oldData.map((d) =>
          d.uid === uid ? { ...d, isActive: true } : d,
        )
      })
    },
  })
}
export const useStopGenesysImporter = (): UseMutationResult<
  ListGenesysResponse,
  ResponseError,
  string,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: CACHE_KEY,
    mutationFn: (uid: string) => request.post(`importer/genesys/${uid}/stop`),
    onSuccess: (_, uid) => {
      queryClient.setQueryData<ListGenesysResponse[]>(CACHE_KEY, (oldData) => {
        if (!oldData) {
          return []
        }
        return oldData.map((d) =>
          d.uid === uid ? { ...d, isActive: false } : d,
        )
      })
    },
  })
}
