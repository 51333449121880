import request, { ResponseError } from '@capturi/request'
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { TokenInfo } from './models/ApiToken'

const cacheKey = 'api-tokens'

const selectTokens = (data: TokenInfo[]): TokenInfo[] => {
  return data?.sort((a, b) => b.issuedAt.valueOf() - a.issuedAt.valueOf())
}

export const useApiTokens = (): UseQueryResult<TokenInfo[], ResponseError> =>
  useQuery({
    queryKey: [cacheKey],
    queryFn: async () => {
      const result = await request.get<{ tokens: TokenInfo[] }>(
        'authentication/organization/api-token',
      )
      return result.tokens
    },
    select: selectTokens,
  })

export const useCreateApiToken = (): UseMutationResult<
  TokenInfo,
  ResponseError,
  string,
  ResponseError
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [cacheKey],
    mutationFn: (name: string) => {
      return request.post<TokenInfo>(
        'authentication/organization/create-api-token',
        {
          json: { name },
        },
      )
    },
    onSuccess: (newToken) => {
      queryClient.setQueriesData<TokenInfo[]>(
        { queryKey: [cacheKey] },
        (oldData) => {
          if (!oldData) {
            return [newToken]
          }
          return [newToken, ...oldData]
        },
      )
    },
  })
}
