import { Box, Flex, Tag, TagCloseButton, TagLabel } from '@chakra-ui/react'
import React, { ReactElement, useRef, useState } from 'react'

type Props = {
  value: string[]
  onChange: (value: string[]) => void
  placeholder?: string
  splitOnWhiteSpace?: boolean
  splitOnComma?: boolean
}
type SplitOptions = { splitOnWhiteSpace: boolean; splitOnComma: boolean }

const getSplitRegex = ({ splitOnWhiteSpace, splitOnComma }: SplitOptions) => {
  if (splitOnWhiteSpace && splitOnComma) return /,|\s/
  if (splitOnWhiteSpace === false && splitOnComma === true) return /,|\n/
  if (splitOnWhiteSpace === true && splitOnComma === false) return /\s/
  return null
}

const split = (text: string, o: SplitOptions) => {
  const regex = getSplitRegex(o)

  if (regex === null) return text.trim()

  return text
    .split(regex)
    .map((w) => w.trim())
    .filter(Boolean)
}

const getSplitKeys = (o: SplitOptions) => {
  const whitespace = o.splitOnWhiteSpace ? ' ' : null
  const comma = o.splitOnComma ? ',' : null

  return ['Enter', 'Tab', comma, whitespace].filter((key) => key !== null)
}

export const ChipsInput: React.FC<Props> = ({
  value,
  onChange,
  placeholder,
  splitOnWhiteSpace = true,
  splitOnComma = true,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [newPhrase, setNewPhrase] = useState<string>('')

  const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>): void => {
    event.preventDefault()
    const text = event.clipboardData.getData('Text')

    const values = split(text, { splitOnComma, splitOnWhiteSpace })

    const newState = [...value, ...values]
    onChange(newState)
    setNewPhrase('')
    inputRef?.current?.focus()
  }

  const handleAdd = (): void => {
    if (newPhrase) {
      onChange([...value, newPhrase])
      setNewPhrase('')
    }
  }

  const removePhrase = (index: number): void => {
    const newState = [...value]
    newState.splice(index, 1)
    onChange(newState)
    inputRef?.current?.focus()
  }

  const handleKeypress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    const splitKey = getSplitKeys({ splitOnComma, splitOnWhiteSpace })
    if (newPhrase && splitKey.includes(e.key)) {
      e.preventDefault()
      handleAdd()
    }
  }

  const renderPhrase = (phrase: string, index: number): ReactElement => {
    return (
      <Tag m="1" justifyContent="space-between" key={index} borderRadius="999">
        <TagLabel>{phrase}</TagLabel>
        <TagCloseButton onClick={() => removePhrase(index)} />
      </Tag>
    )
  }

  return (
    <Box>
      <Flex
        onClick={() => inputRef?.current?.focus()}
        border="solid 1px"
        borderColor="border.light"
        borderRadius="md"
        height="100%"
        transition="200ms padding ease-out"
        padding={1}
        justify="space-between"
        flexDirection="column"
        _focusWithin={{
          borderColor: 'primary.500',
          boxShadow: '0 0 0 1px var(--chakra-colors-primary-500)',
        }}
      >
        <Flex wrap="wrap" alignItems={'center'}>
          {value.map(renderPhrase)}
          <Box
            as="input"
            fontSize="sm"
            px={2}
            my="6px"
            outline={0}
            placeholder={placeholder}
            ref={inputRef}
            onBlur={handleAdd}
            onKeyDown={handleKeypress}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setNewPhrase(e.target.value.toLocaleLowerCase())
            }
            value={newPhrase}
            onPaste={handlePaste}
          />
        </Flex>
      </Flex>
    </Box>
  )
}
