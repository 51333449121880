import request from '@capturi/request'
import { useOrganization } from '@capturi/stores'
import { Button } from '@capturi/ui-components'
import {
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React, { useState } from 'react'

import { useInput } from '@capturi/react-utils'
import {
  type GenesysAuth,
  regions,
  useAddGenesysImporter,
} from './useGenesysImporter'

type Props = {
  onClose: () => void
}

const AddGenesys: React.FC<Props> = ({ onClose }) => {
  const { mutate: addImporter, isPending, error } = useAddGenesysImporter()
  const { name } = useOrganization()
  const [state, handleSetState] = useInput<GenesysAuth>({
    clientId: '',
    clientSecret: '',
    region: 'mypurecloud.de',
  })
  const [testInProgress, setTestInProgress] = useState(false)
  const [testError, setTestError] = useState('')

  const handleAdd = async (): Promise<void> => {
    setTestInProgress(true)
    setTestError('')

    try {
      await request.post('importer/genesys/test-auth', {
        json: { auth: state },
      })

      addImporter(
        {
          auth: state,
          friendlyName: `${name} - Genesys`,
          continuationToken: new Date(),
          filters: {
            whitelistedInboxes: [],
            blacklistedInboxes: [],
            blacklistedDomains: [],
            blacklistedEmails: [],
            whitelistedDirections: ['Inbound', 'Outbound', 'AutoReply'],
          },
          options: {
            cutOffWords: [],
          },
        },
        {
          onSuccess: () => {
            onClose()
          },
          onError: () => {
            setTestInProgress(false)
          },
        },
      )
    } catch (_error) {
      setTestInProgress(false)
      setTestError('Invalid authentication')
    }
  }
  const inProgress = isPending || testInProgress
  return (
    <Modal isOpen={true} onClose={onClose} closeOnEsc={!inProgress}>
      <ModalOverlay>
        <ModalContent>
          <Flex direction="column">
            <ModalHeader>
              <Trans>Genesys importer</Trans>
            </ModalHeader>
          </Flex>
          {!inProgress && <ModalCloseButton />}
          <ModalBody>
            <Stack spacing={4}>
              <FormControl>
                <FormLabel htmlFor="clientSecret">Client Id</FormLabel>
                <Input
                  isDisabled={isPending}
                  placeholder={'clientId'}
                  {...handleSetState.clientId}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="clientSecret">Client Secret</FormLabel>
                <Input
                  isDisabled={isPending}
                  placeholder={'clientSecret'}
                  {...handleSetState.clientSecret}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="Region">
                  <Trans>Region</Trans>
                </FormLabel>
                <Select {...handleSetState.region}>
                  {regions.map((region) => (
                    <option value={region} key={region}>
                      {region}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <div>
              <HStack spacing={4}>
                <Button secondary isDisabled={inProgress} onClick={onClose}>
                  <Trans>Cancel</Trans>
                </Button>
                <Button
                  primary
                  isLoading={inProgress}
                  mr={3}
                  onClick={handleAdd}
                >
                  <Trans>Add Genesys importer</Trans>
                </Button>
              </HStack>
              <Text textAlign="end" color="red">
                {testError || error?.message}
              </Text>
            </div>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

export default AddGenesys
