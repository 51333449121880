import { Button, Card } from '@capturi/ui-components'
import { Flex, Grid, Heading } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import React from 'react'

import {
  ListMockResponse,
  useStartMockImporter,
  useStopMockImporter,
} from './useMockImporter'

type Props = ListMockResponse

const Config: React.FC<Props> = (props) => {
  const { isActive, uid } = props
  const { mutate: start, isPending: isLoadingStart } = useStartMockImporter()
  const { mutate: stop, isPending: isLoadingStop } = useStopMockImporter()

  return (
    <Card p="4" key={uid}>
      <Flex mb="8">
        <Heading>Mock Importer Configuration</Heading>
      </Flex>
      <Grid gridTemplateColumns="1fr 1fr" gap="8" />

      <Flex mt="4" justifyContent="space-between">
        <Button
          colorScheme={isActive ? 'red' : 'green'}
          isLoading={isLoadingStart || isLoadingStop}
          onClick={isActive ? () => stop(uid) : () => start(uid)}
        >
          {isActive ? t`Stop` : t`Start`}
        </Button>
      </Flex>
    </Card>
  )
}

export default Config
