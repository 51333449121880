import request, { ResponseError } from '@capturi/request'
import {
  UseMutationResult,
  UseQueryResult,
  UseSuspenseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query'

import { t } from '@lingui/macro'
import { isEmpty } from 'lodash'
import React from 'react'
import { selectDeletionPolicy } from '../tabs/DeletionPolicy'
import { SystemSettings, SystemSettingsModel } from '../types'

const url = 'system/settings?api-version=3.3'
const cacheKey = ['system-settings']

//Also temporary until after summer break.
type RepeatCallBlacklistResponseModel = {
  organizationUid: string
  created: Date
  createdByUserUid: string
  updated: Date
  updatedByUserUid: string
  customers: string[]
}

export const useSystemSettings = <T = SystemSettings>({
  select,
}: {
  select?: ((data: SystemSettings) => T) | undefined
}): UseSuspenseQueryResult<T, ResponseError> => {
  return useSuspenseQuery({
    queryKey: cacheKey,
    queryFn: () => request.get<SystemSettings>(url),
    select: select,
  })
}

export const useUpdateSystemSettings = (): UseMutationResult<
  SystemSettings,
  ResponseError,
  Partial<SystemSettingsModel>,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (model: Partial<SystemSettingsModel>) =>
      request.patch<SystemSettings>(url, {
        json: model,
      }),
    onSuccess: (newSystemSettings) => {
      queryClient.setQueryData<SystemSettings>(cacheKey, () => {
        return newSystemSettings
      })
    },
  })
}

//These are temporary until after summer break.
//Backend will then expose the repeat call blacklist in the orgSettings endpoint
export const useRepeatCallsBlacklist = (): UseQueryResult<
  RepeatCallBlacklistResponseModel,
  ResponseError
> => {
  return useQuery({
    queryKey: ['repeatCallsBlacklist'],
    queryFn: () =>
      request.get<RepeatCallBlacklistResponseModel>(
        'repeat-calls/blacklist?api-version=3.3',
      ),
  })
}

export const useUpdateRepeatCallsBlacklist = (): UseMutationResult<
  string[],
  ResponseError,
  string[],
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (model: string[]) =>
      request.patch<string[]>('repeat-calls/blacklist?api-version=3.3', {
        json: { customers: model },
      }),
    onSuccess: (newRepeatCallBlacklist) => {
      queryClient.setQueryData<string[]>(['repeatCallsBlacklist'], () => {
        return newRepeatCallBlacklist
      })
    },
  })
}

type ValidationErrors = {
  conversationSoftTimeToLive?: string
  conversationHardTimeToLive?: string
  audioSnippetTimeToLive?: string
  caseTimeToLive?: string
}

const minDays = 7
function validate(data: DeletionPolicyFormData): ValidationErrors {
  const errors: ValidationErrors = {}

  if (data.conversationSoftTimeToLive === undefined) {
    errors.conversationSoftTimeToLive = t`*value is required`
  } else if (data.conversationSoftTimeToLive < minDays) {
    errors.conversationSoftTimeToLive = t`*value needs to be set to at least ${minDays} days`
  } else if (
    data.conversationHardTimeToLive !== undefined &&
    data.conversationSoftTimeToLive > data.conversationHardTimeToLive
  ) {
    errors.conversationSoftTimeToLive = t`*value must be lower than the value in “Keep conversations statistics for”`
  }

  if (data.conversationHardTimeToLive === undefined) {
    errors.conversationHardTimeToLive = t`*value is required`
  } else if (data.conversationHardTimeToLive < minDays) {
    errors.conversationHardTimeToLive = t`*value needs to be set to at least ${minDays} days`
  } else if (
    data.conversationSoftTimeToLive !== undefined &&
    data.conversationHardTimeToLive < data.conversationSoftTimeToLive
  ) {
    errors.conversationHardTimeToLive = t`*value must be greater than the value in “Keep conversation audio for”`
  }

  if (data.audioSnippetTimeToLive === undefined) {
    errors.audioSnippetTimeToLive = t`*value is required`
  } else {
    if (data.audioSnippetTimeToLive < minDays) {
      errors.audioSnippetTimeToLive = t`*value needs to be set to at least ${minDays} days`
    }
  }

  return errors
}

export type DeletionPolicyFormData = {
  conversationSoftTimeToLive?: number
  conversationHardTimeToLive?: number
  audioSnippetTimeToLive?: number
  caseTimeToLive?: number
  anonymizeAsrData?: boolean
}

type UseDeletionPolicyForm = {
  formData: DeletionPolicyFormData
  isValid: boolean
  validationErrors: {
    conversationSoftTimeToLive?: string
    conversationHardTimeToLive?: string
    audioSnippetTimeToLive?: string
    caseTimeToLive?: string
  }
  hasChanges: boolean
  updateForm: (data: Partial<DeletionPolicyFormData>) => void
  resetForm: () => void
}

export const useDeletionPolicyForm = (): UseDeletionPolicyForm => {
  const { data } = useSystemSettings({ select: selectDeletionPolicy })
  const initialData = React.useMemo(
    () => ({
      conversationSoftTimeToLive: data?.conversationSoftTimeToLive,
      conversationHardTimeToLive: data?.conversationHardTimeToLive,
      audioSnippetTimeToLive: data?.audioSnippetTimeToLive,
      caseTimeToLive: data?.caseTimeToLive,
      anonymizeAsrData: data?.anonymizeAsrData,
    }),
    [data],
  )

  const [formData, setFormData] =
    React.useState<DeletionPolicyFormData>(initialData)

  const handleUpateForm = React.useCallback(
    (data: Partial<DeletionPolicyFormData>) => {
      setFormData((prev) => ({ ...prev, ...data }))
    },
    [],
  )

  const resetForm = React.useCallback(() => {
    setFormData(initialData)
  }, [initialData])

  return React.useMemo(() => {
    const hasChanges =
      formData.conversationSoftTimeToLive !==
        data?.conversationSoftTimeToLive ||
      formData.conversationHardTimeToLive !==
        data?.conversationHardTimeToLive ||
      formData.caseTimeToLive !== data?.caseTimeToLive ||
      formData.audioSnippetTimeToLive !== data?.audioSnippetTimeToLive ||
      formData.anonymizeAsrData !== data?.anonymizeAsrData

    const validationErrors = validate(formData)

    return {
      data,
      formData,
      isValid: isEmpty(validationErrors),
      validationErrors: validationErrors,
      hasChanges: hasChanges,
      updateForm: handleUpateForm,
      resetForm,
    }
  }, [data, formData, handleUpateForm, resetForm])
}
