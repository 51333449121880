import request, { ResponseError } from '@capturi/request'
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

const CACHE_KEY = ['importers', 'zendesk']

export type ZendeskFilters = {
  blacklistedSenderDomains: string[]
  blacklistedSenderEmails: string[]
  whitelistedGroupIds: number[]
  blacklistedGroupIds: number[]
  whitelistedDirections: string[]
}

export type ListZendeskResponse = {
  auth: {
    email: string
    // token: string
    domain: string
  }
  filters: ZendeskFilters
  lastRunStarted: Date
  lastRunEnded: Date
  continuationToken: Date
  customFields: number[]
  friendlyName: string
  uid: string
  organizationUid: string
  isActive: boolean
}

export type AddZendeskPayload = {
  auth: {
    email: string
    token: string
    domain: string
  }
  friendlyName: string
  continuationToken: Date
}
export type PatchZendeskPayload = Partial<{
  auth: Partial<{
    email: string
    token: string
    domain: string
  }>
  friendlyName: string
  continuationToken: Date
  customFields: number[]
  filters: Partial<ZendeskFilters>
}>

export const useZendeskImporter = (): UseQueryResult<
  ListZendeskResponse[],
  ResponseError
> =>
  useQuery({
    queryKey: CACHE_KEY,
    queryFn: () => request.get<ListZendeskResponse[]>('importer/zendesk/list'),
  })

export const useAddZendeskImporter = (): UseMutationResult<
  ListZendeskResponse,
  ResponseError,
  AddZendeskPayload,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: CACHE_KEY,
    mutationFn: (model: AddZendeskPayload) =>
      request.post('importer/zendesk/add', { json: model }),
    onSuccess: (importer) => {
      queryClient.setQueryData<ListZendeskResponse[]>(CACHE_KEY, (oldData) => {
        if (!oldData) {
          return [importer]
        }
        return [...oldData, importer]
      })
    },
  })
}

type UpdateImporter = { uid: string; payload: PatchZendeskPayload }
export const useUpdateZendeskImporter = (): UseMutationResult<
  ListZendeskResponse,
  ResponseError,
  UpdateImporter,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: CACHE_KEY,
    mutationFn: ({ uid, payload }) =>
      request.patch(`importer/zendesk/${uid}`, { json: payload }),
    onSuccess: (importer, { uid }) => {
      queryClient.setQueryData<ListZendeskResponse[]>(CACHE_KEY, (oldData) => {
        if (!oldData) {
          return [importer]
        }

        return oldData.map((d) => (d.uid === uid ? { ...d, ...importer } : d))
      })
    },
  })
}
export const useStartZendeskImporter = (): UseMutationResult<
  ListZendeskResponse,
  ResponseError,
  string,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: CACHE_KEY,
    mutationFn: (uid: string) => request.post(`importer/zendesk/${uid}/start`),
    onSuccess: (_, uid) => {
      queryClient.setQueryData<ListZendeskResponse[]>(CACHE_KEY, (oldData) => {
        if (!oldData) {
          return []
        }
        return oldData.map((d) =>
          d.uid === uid ? { ...d, isActive: true } : d,
        )
      })
    },
  })
}
export const useStopZendeskImporter = (): UseMutationResult<
  ListZendeskResponse,
  ResponseError,
  string,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: CACHE_KEY,
    mutationFn: (uid: string) => request.post(`importer/zendesk/${uid}/stop`),
    onSuccess: (_, uid) => {
      queryClient.setQueryData<ListZendeskResponse[]>(CACHE_KEY, (oldData) => {
        if (!oldData) {
          return []
        }
        return oldData.map((d) =>
          d.uid === uid ? { ...d, isActive: false } : d,
        )
      })
    },
  })
}
