import analytics from '@capturi/analytics'
import { getErrorMessage } from '@capturi/request'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@capturi/ui-components'
import { Flex, FormControl, FormLabel, Input, Stack } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'

import { useTeamsApiContext } from '../../hooks/useTeamsApi'

type Props = { uid: string | null; name: string | null; onClose: () => void }

const CreateUpdateTeam: React.FC<Props> = ({
  uid,
  name: originalName,
  onClose,
}) => {
  const { actions } = useTeamsApiContext()
  const toast = useToast()

  const nameRef = useRef<HTMLInputElement>(null)

  const [name, setName] = useState(originalName || '')
  const [inProgress, setInProgress] = useState(false)
  // const [deleteInProgress, setDeleteInProgress] = useState(false)

  // const [deleteError, setDeleteError] = useState<Error | null>(null)

  // const {
  //   isOpen: isDeleteDialogOpen,
  //   onOpen: onOpenDeleteDialog,
  //   onClose: onCloseDeleteDialog,
  // } = useDisclosure()

  useEffect(() => {
    if (uid) {
      analytics.modalview('updateTeam')
      analytics.event('organization_teams_openUpdate')
    } else {
      analytics.modalview('createTeam')
      analytics.event('organization_teams_openCreate')
    }
  }, [uid])

  const handleSubmit = async (): Promise<void> => {
    try {
      setInProgress(true)

      if (uid) {
        await actions.update(uid, name)
        analytics.event('organization_teams_update')
      } else {
        await actions.create(name)
        analytics.event('organization_teams_create')
      }

      setInProgress(false)
      toast({ title: t`Team created`, status: 'success' })

      onClose()
    } catch (error) {
      setInProgress(false)
      const errorMsg = getErrorMessage(error)

      toast({
        title: t`We made a mistake, sorry :'(`,
        description: errorMsg,
        status: 'error',
      })
    }
  }

  // const handleDelete = async (): Promise<void> => {
  //   if (!uid) return
  //   try {
  //     setDeleteInProgress(true)
  //     await actions.delete(uid)

  //     setDeleteInProgress(false)
  //     toast({ title: t`Team deleted`, status: 'success' })
  //     onClose()
  //   } catch (e) {
  //     const error = await getErrorObject(e)

  //     setDeleteError(error)
  //     toast({
  //       title: t`Woops, that did not go so well`,
  //       description: (await error).message,
  //       status: 'error',
  //     })
  //     setDeleteInProgress(false)
  //   }
  // }

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      initialFocusRef={nameRef}
      closeOnEsc={!inProgress}
    >
      <ModalOverlay>
        <ModalContent>
          <Flex direction="column">
            <ModalHeader>
              {uid ? <Trans>Edit team</Trans> : <Trans>Add team</Trans>}
            </ModalHeader>
          </Flex>
          {!inProgress && <ModalCloseButton />}
          <ModalBody>
            <FormControl>
              <FormLabel htmlFor="name">
                <Trans>Name</Trans>
              </FormLabel>
              <Input
                id="name"
                ref={nameRef}
                onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                  setName(e.target.value)
                }
                value={name}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter
            justifyContent="space-between"
            flexDirection="row-reverse"
          >
            <Stack isInline spacing={4}>
              <Button secondary isDisabled={inProgress} onClick={onClose}>
                <Trans>Cancel</Trans>
              </Button>
              <Button
                primary
                isLoading={inProgress}
                onClick={handleSubmit}
                isDisabled={!name || originalName === name}
              >
                {uid ? <Trans>Save</Trans> : <Trans>Create</Trans>}
              </Button>
            </Stack>
            {/* {uid && (
              <Button
                colorScheme="red"
                onClick={onOpenDeleteDialog}
                isDisabled={inProgress}
              >
                <Trans>Delete</Trans>
              </Button>
            )} */}
          </ModalFooter>
        </ModalContent>
        {/* {isDeleteDialogOpen && (
          <DeleteDialog
            title={<Trans>Are you sure?</Trans>}
            message={
              <Trans>Are you sure you want to delete the selected team?</Trans>
            }
            onConfirm={handleDelete}
            confirmText={<Trans>Delete team</Trans>}
            cancelText={<Trans>Cancel</Trans>}
            onClose={onCloseDeleteDialog}
            inProgress={deleteInProgress}
            errorMessage={deleteError?.message}
          />
        )} */}
      </ModalOverlay>
    </Modal>
  )
}

export default CreateUpdateTeam
