import { Box, HStack, Icon, Input } from '@chakra-ui/react'
import React from 'react'
import { MdClose } from 'react-icons/md'
import { WebhookHeader } from '../Api/Webhooks/models/Webhooks'

type Props = {
  headers: WebhookHeader[]

  onHeaderPropChange: (index: number, key?: string, value?: string) => void
  onRemoveHeaderChange: (index: number) => void
}

const HttpHeader: React.FC<Props> = ({
  headers,
  onHeaderPropChange,
  onRemoveHeaderChange,
}) => {
  return (
    <>
      {headers.map((header, index) => (
        <HStack mt={2} key={index}>
          <Box>
            <Input
              value={header.key ?? Object.keys(header)}
              onChange={(e) => {
                onHeaderPropChange(index, e.target.value, undefined)
              }}
            />
          </Box>
          <Box>
            <Input
              value={header.value ?? Object.values(header)}
              onChange={(e) => {
                onHeaderPropChange(index, undefined, e.target.value)
              }}
            />
          </Box>
          <Icon as={MdClose} onClick={() => onRemoveHeaderChange(index)} />
        </HStack>
      ))}
    </>
  )
}

export default HttpHeader
