import React from 'react'

import Config from './Config'
import { useZendeskImporter } from './useZendeskImporter'

const Zendesk: React.FC = () => {
  const { data = [] } = useZendeskImporter()

  return data.map((d) => <Config key={d.uid} {...d} />)
}

export default Zendesk
