import {
  Flex,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
} from '@chakra-ui/react'
import React from 'react'

const DaysNumberInput: React.FC<{
  isDisabled: boolean
  value?: number
  onChange: (value: number | undefined) => void
}> = ({ isDisabled, onChange, value }) => {
  return (
    <Flex alignItems="center" ml={1}>
      <NumberInput
        inputMode="numeric"
        isDisabled={isDisabled}
        w="80px"
        keepWithinRange={false}
        size="sm"
        onChange={(valueAsString, valueAsNumber) => {
          if (valueAsString === '') {
            onChange?.(undefined)
            return
          }
          if (!Number.isInteger(valueAsNumber)) {
            return
          }

          onChange?.(valueAsNumber)
        }}
        value={value}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      <Text fontSize="sm" ml="2">
        days
      </Text>
    </Flex>
  )
}
export default DaysNumberInput
