import { Button, Card, useToast } from '@capturi/ui-components'
import {
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Input,
  Tooltip,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { lightFormat } from 'date-fns/fp'
import React, { useState } from 'react'
import isEqual from 'react-fast-compare'
import CustomFieldSelector from '../CustomFieldSelector'
import {
  useToggleTextImporter,
  useUpdateTextImporter,
} from '../useTextImporter'
import {
  type BoostAiPatchPayload,
  type BoostAiResponse,
  URL_KEY,
} from './types'

const formatDate = lightFormat("yyy-MM-dd'T'HH:mm")

type Props = BoostAiResponse

const Config: React.FC<Props> = (props) => {
  const { isActive, uid } = props
  const { mutate, isPending } =
    useUpdateTextImporter<BoostAiPatchPayload>(URL_KEY)
  const { mutate: toggle, isPending: isLoadingToggle } =
    useToggleTextImporter(URL_KEY)

  const [mostRecentTicket, setMostRecentTicket] = useState<string>(
    formatDate(props.continuationToken),
  )
  const [customFields, setCustomFields] = useState<string[]>(props.customFields)

  const toast = useToast()

  const isDirty = !(
    // it would be better to compare the date-objects, but the html date-picker doesn't have seconds
    (
      isEqual(mostRecentTicket, formatDate(props.continuationToken)) &&
      isEqual(props.customFields, customFields)
    )
  )

  const handleUpdate = (): void => {
    const payload: BoostAiPatchPayload = {
      continuationToken: new Date(mostRecentTicket),

      filters: {},
      customFields,
    }

    mutate(
      { uid, payload },
      {
        onSuccess: () => {
          toast({
            title: t`Configuration updated`,
            status: 'success',
          })
        },
        onError: (error) => {
          toast({
            title: t`Could not update configuration`,
            description: error.message,
            status: 'error',
          })
        },
      },
    )
  }

  return (
    <Card p="4" key={uid}>
      <Flex mb="8">
        <Heading>Boost.ai Importer Configuration</Heading>
      </Flex>
      <Grid gridTemplateColumns="1fr 1fr" gap="8">
        <FormControl>
          <FormLabel htmlFor="Last imported ticket">
            <Trans>Last imported ticket</Trans>
          </FormLabel>
          <Input
            size="sm"
            type="datetime-local"
            onChange={(e) => setMostRecentTicket(e.currentTarget.value)}
            value={mostRecentTicket}
            isDisabled={isPending}
          />
        </FormControl>
        <CustomFieldSelector
          baseUrl={URL_KEY}
          isDisabled={isPending}
          onChange={setCustomFields}
          integrationUid={uid}
          initialCostumFields={customFields}
        />
      </Grid>

      <Flex mt="4" justifyContent="space-between">
        <Button
          colorScheme={isActive ? 'red' : 'green'}
          isLoading={isLoadingToggle}
          onClick={() => toggle({ uid, action: isActive ? 'stop' : 'start' })}
        >
          {isActive ? t`Stop` : t`Start`}
        </Button>
        <Tooltip label={t`No changes to update`} isDisabled={isDirty}>
          <Button
            isDisabled={!isDirty || isLoadingToggle}
            onClick={handleUpdate}
            primary
            isLoading={isPending}
          >
            <Trans>Update Configuration</Trans>
          </Button>
        </Tooltip>
      </Flex>
    </Card>
  )
}

export default Config
