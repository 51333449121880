import request from '@capturi/request/src/request'
import { Button, useToast } from '@capturi/ui-components'
import {
  Card,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { useMutation } from '@tanstack/react-query'
import React, { useState } from 'react'

type Props = { uid: string; friendlyName: string }

const BackSync: React.FC<Props> = ({ friendlyName, uid }) => {
  const [from, setFrom] = useState('')
  const [to, setTo] = useState('')
  const toast = useToast()
  const { mutate, isPending } = useMutation({
    mutationFn: async () =>
      request.post('importer/genesys/back-sync', {
        json: {
          uid: uid,
          from: new Date(from),
          to: new Date(to),
        },
      }),
  })

  return (
    <Card p="4" mt="4">
      <Text fontSize="2xl">
        <Trans>Back sync messages for {friendlyName}</Trans>
      </Text>
      <Text>
        <Trans>
          This will start a job, back syncing messages. It will take some time
          to finish
        </Trans>
      </Text>
      <Flex mt="4">
        <FormControl>
          <FormLabel htmlFor="Last imported ticket">
            <Trans>From</Trans>
          </FormLabel>
          <Input
            size="sm"
            type="datetime-local"
            onChange={(e) => setFrom(e.currentTarget.value)}
            value={from}
            isDisabled={isPending}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="Last imported ticket">
            <Trans>To</Trans>
          </FormLabel>
          <Input
            size="sm"
            type="datetime-local"
            onChange={(e) => setTo(e.currentTarget.value)}
            value={to}
            isDisabled={isPending}
          />
        </FormControl>
      </Flex>
      <Flex justifyContent="end" mt="4">
        <Button
          isDisabled={to === '' || from === ''}
          primary
          isLoading={isPending}
          onClick={() =>
            mutate(undefined, {
              onSuccess: () => {
                setFrom('')
                setTo('')
                toast({ status: 'success', title: t`Back sync job started` })
              },
              onError: (error) => {
                toast({
                  status: 'error',
                  title: t`Something went wrong`,
                  description: error.message,
                })
              },
            })
          }
        >
          Back sync
        </Button>
      </Flex>
    </Card>
  )
}

export default BackSync
