import React from 'react'

import Config from './Config'
import { useMockImporter } from './useMockImporter'

const Mock: React.FC = () => {
  const { data = [] } = useMockImporter()

  return data.map((d) => <Config key={d.uid} {...d} />)
}

export default Mock
