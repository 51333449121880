import React from 'react'

import Config from './Config'
import { useDixaImporter } from './useDixaImporter'

const Dixa: React.FC = () => {
  const { data = [] } = useDixaImporter()

  return data.map((d) => <Config key={d.uid} {...d} />)
}

export default Dixa
