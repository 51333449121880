import request, { ResponseError } from '@capturi/request'
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

const CACHE_KEY = ['settings', 'widget']

export type SummaryWidgetConfig = {
  name: string
  createdBy: string
  organizationUid: string
  daysToLookBack: number
  language: string
  maxNumberOfSummeries: number
  token: string
  uid: string
  waitForConnectionBeforeShowingSummary: boolean
  widgetType: string
}
type SummaryWidgetConfigRequest = {
  name: string
  daysToLookBack: number
  language: string
  maxNumberOfSummeries: number
  waitForConnectionBeforeShowingSummary: boolean
}

//documentation can be found here:
// https://api.capturi.ai/puzzel-widget/documentation/swagger
export const useSummaryWidgetConfig = (): UseQueryResult<
  SummaryWidgetConfig[],
  ResponseError
> =>
  useQuery({
    queryKey: CACHE_KEY,
    queryFn: () => request.get<SummaryWidgetConfig[]>('puzzel-widget/token'),
  })

export const useCreateSummaryWidgetConfig = (): UseMutationResult<
  SummaryWidgetConfig,
  ResponseError,
  SummaryWidgetConfigRequest,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: CACHE_KEY,
    mutationFn: (model: SummaryWidgetConfigRequest) =>
      request.post('puzzel-widget/token', { json: model }),
    onSuccess: (config) => {
      queryClient.setQueryData<SummaryWidgetConfig[]>(CACHE_KEY, (oldData) => {
        if (!oldData) {
          return [config]
        }
        return [...oldData, config]
      })
    },
  })
}

type UpdateConfig = { uid: string; payload: SummaryWidgetConfigRequest }
export const useUpdateSummaryWidgetConfig = (): UseMutationResult<
  SummaryWidgetConfig,
  ResponseError,
  UpdateConfig,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: CACHE_KEY,
    mutationFn: ({ uid, payload }) =>
      request.put(`puzzel-widget/token/${uid}`, { json: payload }),
    onSuccess: (config, { uid }) => {
      queryClient.setQueryData<SummaryWidgetConfig[]>(CACHE_KEY, (oldData) => {
        if (!oldData) {
          return [config]
        }

        return oldData.map((d) => (d.uid === uid ? { ...d, ...config } : d))
      })
    },
  })
}

export const useDeleteSummaryWidgetConfig = (): UseMutationResult<
  SummaryWidgetConfig,
  ResponseError,
  string,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: CACHE_KEY,
    mutationFn: (uid) => request.delete(`puzzel-widget/token/${uid}`),
    onSuccess: (_config, uid) => {
      queryClient.setQueryData<SummaryWidgetConfig[]>(CACHE_KEY, (oldData) => {
        if (!oldData) {
          return []
        }

        return oldData.filter((d) => d.uid !== uid)
      })
    },
  })
}
