import { useInput } from '@capturi/react-utils'
import { getErrorObject } from '@capturi/request'
import {
  Button,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import { FormControl, Input, Select, Switch, VStack } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import noop from 'lodash/noop'
import React from 'react'

import {
  useCreateSummaryWidgetConfig,
  useUpdateSummaryWidgetConfig,
} from '../../Api/SummaryWidget/useSummaryWidgetConfig'

type Props = {
  config?: {
    uid: string
    daysToLookBack: string
    maxNumberOfSummeries: string
    name: string
    waitForConnectionBeforeShowingSummary: boolean
    widgetType: string
  }
  currentUserName: string
} & BaseModalComponentProps

const SummaryWidgetModal: React.FC<Props> = ({
  onClose,
  config,
  currentUserName,
}) => {
  const { mutate: add, isPending: isAdding } = useCreateSummaryWidgetConfig()
  const { mutate: update, isPending: isUpdating } =
    useUpdateSummaryWidgetConfig()
  const toast = useToast()
  const {
    daysToLookBack = '90',
    maxNumberOfSummeries = '10',
    name = `${currentUserName}'s summary widget`,
    waitForConnectionBeforeShowingSummary = true,
    widgetType = 'puzzel',
  } = config || {}
  const isLoading = isAdding || isUpdating
  const [inputs, setInputs] = useInput({
    name,
    daysToLookBack,
    maxNumberOfSummeries,
    waitForConnectionBeforeShowingSummary,
    widgetType,
  })

  const isNew = !config

  const handleConfirm = (): void => {
    const payload = {
      daysToLookBack: Number(inputs.daysToLookBack),
      maxNumberOfSummeries: Number(inputs.maxNumberOfSummeries),
      language: 'da',
      name: inputs.name,
      waitForConnectionBeforeShowingSummary:
        inputs.waitForConnectionBeforeShowingSummary,
      widgetType: inputs.widgetType,
    }

    if (!config) {
      add(payload, {
        onError: (error) => {
          const { message, name } = getErrorObject(error)
          toast({ title: name, description: message, status: 'error' })
        },
        onSuccess: () => {
          toast({ title: 'Widget added', status: 'success' })
          onClose()
        },
      })
    } else {
      update(
        {
          uid: config.uid,
          payload: payload,
        },
        {
          onError: (error) => {
            const { message, name } = getErrorObject(error)
            toast({ title: name, description: message, status: 'error' })
          },
          onSuccess: () => {
            toast({ title: 'Widget Updated', status: 'success' })
            onClose()
          },
        },
      )
    }
  }

  return (
    <Modal isOpen onClose={isLoading ? noop : onClose}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>
            {isNew ? t`Create widget` : t`Update widget`}
          </ModalHeader>

          <ModalCloseButton isDisabled={isLoading} />
          <ModalBody>
            <VStack spacing={4}>
              <FormControl>
                <FormLabel>
                  <Trans>Name</Trans>
                </FormLabel>
                <Input
                  isDisabled={isLoading}
                  bg="white"
                  placeholder="Config name"
                  {...setInputs.name}
                />
              </FormControl>
              <FormControl>
                <FormLabel>
                  <Trans>Days to look back</Trans>
                </FormLabel>
                <Input
                  isDisabled={isLoading}
                  bg="white"
                  placeholder="Days to look back"
                  {...setInputs.daysToLookBack}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="comment">
                  <Trans>Number of summaries to show</Trans>
                </FormLabel>
                <Input
                  isDisabled={isLoading}
                  bg="white"
                  placeholder="maxNumberOfSummeries"
                  {...setInputs.maxNumberOfSummeries}
                />
              </FormControl>
              <FormControl>
                <FormLabel>
                  <Trans>Type</Trans>
                </FormLabel>
                <Select id="widget-type" {...setInputs.widgetType}>
                  <option value="puzzel">Puzzel</option>
                  <option value="adversus">Adversus</option>
                  <option value="genesys">Genesys</option>
                  <option value="iframe">iFrame</option>
                </Select>
              </FormControl>
              {setInputs.widgetType.value === 'puzzel' && (
                <FormControl>
                  <FormLabel htmlFor="waitForConnectionBeforeShowingSummary">
                    <Trans>
                      Wait until the agent has answered to show the summaries
                    </Trans>
                  </FormLabel>
                  <Switch
                    id="waitForConnectionBeforeShowingSummary"
                    isChecked={inputs.waitForConnectionBeforeShowingSummary}
                    onChange={
                      setInputs.waitForConnectionBeforeShowingSummary.onChange
                    }
                  />
                </FormControl>
              )}
            </VStack>
          </ModalBody>
          <ModalFooter justifyContent="flex-end">
            <Button ml="4" onClick={onClose} isDisabled={isLoading}>
              <Trans>Cancel</Trans>
            </Button>
            <Button
              ml="2"
              primary
              onClick={handleConfirm}
              isLoading={isLoading}
            >
              {isNew ? t`Create` : t`Update`}
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

export default SummaryWidgetModal
