import request, { ResponseError } from '@capturi/request'
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

import { Direction } from '../DirectionSelector'

const CACHE_KEY = ['importers', 'focalscope']

type focalscopeFilters = {
  blacklistedEmails: string[]
  blacklistedDomains: string[]
  whitelistedDirections: Direction[]
  whitelistedFolders: string[]
  blacklistedFolders: string[]
}
type focalscopeOptions = {
  organizationDomains: string[]
  useNewAgentParser: boolean
}

export type ListFocalscopeResponse = {
  auth: {
    secToken: string
    subdomain: string
  }
  filters: focalscopeFilters
  options: focalscopeOptions
  lastRunStarted: Date
  lastRunEnded: Date
  continuationToken: Date
  customFields: string[]
  friendlyName: string
  uid: string
  organizationUid: string
  isActive: boolean
}

export type AddFocalscopePayload = {
  auth: {
    secToken: string
    subdomain: string
  }
  friendlyName: string
  continuationToken: Date
}
export type PatchFocalscopePayload = Partial<{
  auth: Partial<{
    secToken: string
    subdomain: string
  }>
  friendlyName: string
  continuationToken: Date
  customFields: string[]
  filters: Partial<focalscopeFilters>
  options: Partial<focalscopeOptions>
}>

export const useFocalscopeImporter = (): UseQueryResult<
  ListFocalscopeResponse[],
  ResponseError
> =>
  useQuery({
    queryKey: CACHE_KEY,
    queryFn: () =>
      request.get<ListFocalscopeResponse[]>('importer/focalscope/list'),
  })

export const useAddFocalscopeImporter = (): UseMutationResult<
  ListFocalscopeResponse,
  ResponseError,
  AddFocalscopePayload,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: CACHE_KEY,
    mutationFn: (model: AddFocalscopePayload) =>
      request.post('importer/focalscope/add', { json: model }),
    onSuccess: (importer) => {
      queryClient.setQueryData<ListFocalscopeResponse[]>(
        CACHE_KEY,
        (oldData) => {
          if (!oldData) {
            return [importer]
          }
          return [...oldData, importer]
        },
      )
    },
  })
}

type UpdateImporter = { uid: string; payload: PatchFocalscopePayload }
export const useUpdateFocalscopeImporter = (): UseMutationResult<
  ListFocalscopeResponse,
  ResponseError,
  UpdateImporter,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: CACHE_KEY,
    mutationFn: ({ uid, payload }) =>
      request.patch(`importer/focalscope/${uid}`, { json: payload }),
    onSuccess: (importer, { uid }) => {
      queryClient.setQueryData<ListFocalscopeResponse[]>(
        CACHE_KEY,
        (oldData) => {
          if (!oldData) {
            return [importer]
          }

          return oldData.map((d) => (d.uid === uid ? { ...d, ...importer } : d))
        },
      )
    },
  })
}
export const useStartFocalscopeImporter = (): UseMutationResult<
  ListFocalscopeResponse,
  ResponseError,
  string,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: CACHE_KEY,
    mutationFn: (uid: string) =>
      request.post(`importer/focalscope/${uid}/start`),
    onSuccess: (_, uid) => {
      queryClient.setQueryData<ListFocalscopeResponse[]>(
        CACHE_KEY,
        (oldData) => {
          if (!oldData) {
            return []
          }
          return oldData.map((d) =>
            d.uid === uid ? { ...d, isActive: true } : d,
          )
        },
      )
    },
  })
}
export const useStopFocalscopeImporter = (): UseMutationResult<
  ListFocalscopeResponse,
  ResponseError,
  string,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: CACHE_KEY,
    mutationFn: (uid: string) =>
      request.post(`importer/focalscope/${uid}/stop`),
    onSuccess: (_, uid) => {
      queryClient.setQueryData<ListFocalscopeResponse[]>(
        CACHE_KEY,
        (oldData) => {
          if (!oldData) {
            return []
          }
          return oldData.map((d) =>
            d.uid === uid ? { ...d, isActive: false } : d,
          )
        },
      )
    },
  })
}
