import { ChipsInput } from '@capturi/ui-components'
import { Flex, Icon, Text } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { MdPhone } from 'react-icons/md'

type Props = {
  phoneNumbers: string[]
  setPhoneNumbers: (phoneNumbers: string[]) => void
}

const BlacklistPhoneNumbers: React.FC<Props> = ({
  phoneNumbers,
  setPhoneNumbers,
}) => {
  const handleValuesAdded = (newValues: string[]): void => {
    const newList = newValues.reduce((acc, curr) => {
      if (acc.indexOf(curr) === -1) {
        acc.push(curr)
      }
      return acc
    }, phoneNumbers.slice())

    setPhoneNumbers([...newList])
  }

  const handleValueRemoved = (_value: string, index: number): void => {
    const newArray = [...phoneNumbers]
    newArray.splice(index, 1)
    setPhoneNumbers(newArray)
  }

  const emptyPhrasesField = (): void => {
    setPhoneNumbers([])
  }
  return (
    <Flex flexDir="column" w="100%">
      <Flex align="center">
        <Icon as={MdPhone} mr={2} />
        <Text fontWeight="medium" fontSize="md">
          <Trans>Remove these phone numbers from repeat calls</Trans>
        </Text>
      </Flex>
      <Text color="gray.600" fontSize="sm" mb={2}>
        <Trans>
          These phone numbers will not be included in the statistics and will
          not register repeat calls.
        </Trans>
      </Text>
      <ChipsInput
        value={phoneNumbers}
        onValuesAdded={handleValuesAdded}
        onValueRemoved={handleValueRemoved}
        onClearValue={emptyPhrasesField}
        allowUpperCase={true}
        inputPlaceholderText={t`Add phone number`}
      />
    </Flex>
  )
}

export default BlacklistPhoneNumbers
