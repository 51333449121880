import React from 'react'

import Config from './Config'
import { useFocalscopeSftpImporter } from './useFocalscopeSftpImporter'

const FocalscopeSftp: React.FC = () => {
  const { data = [] } = useFocalscopeSftpImporter()

  return data.map((d) => <Config key={d.uid} {...d} />)
}

export default FocalscopeSftp
