import { Box } from '@chakra-ui/react'
import React from 'react'

import ApiTokens from './ApiTokens/Index'
import Webhooks from './Webhooks/Index'
import Widgets from './Widgets/Index'

const ApiWebhooks: React.FC = () => {
  return (
    <Box pb={4}>
      <Box mb={4}>
        <Webhooks />
      </Box>
      <Box mb={4}>
        <ApiTokens />
      </Box>
      <Box mb={4}>
        <Widgets />
      </Box>
    </Box>
  )
}

export default ApiWebhooks
