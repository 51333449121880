import { Button, Card, useToast } from '@capturi/ui-components'
import {
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Input,
  Tooltip,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React, { useState } from 'react'
import isEqual from 'react-fast-compare'
import { ChipsInput } from '../ChipsInput'
import DirectionSelector from '../DirectionSelector'
import { useFilterInput } from '../useFilterInput'
import {
  ListFocalscopeSftpResponse,
  PatchFocalscopeSftpPayload,
  useStartFocalscopeSftpImporter,
  useStopFocalscopeSftpImporter,
  useUpdateFocalscopeSftpImporter,
} from './useFocalscopeSftpImporter'

type Props = ListFocalscopeSftpResponse

const Config: React.FC<Props> = (props) => {
  const { isActive, uid, filters: initialFilters } = props
  const { mutate, isPending } = useUpdateFocalscopeSftpImporter()
  const { mutate: start, isPending: isLoadingStart } =
    useStartFocalscopeSftpImporter()

  const { mutate: stop, isPending: isLoadingStop } =
    useStopFocalscopeSftpImporter()

  const [mostRecentTicket, setMostRecentTicket] = useState<string>(
    props.continuationToken,
  )

  const toast = useToast()

  const [filters, setFilters] = useFilterInput(initialFilters)

  const isDirty = !(
    // it would be better to compare the date-objects, but the html date-picker doesn't have seconds
    (
      isEqual(mostRecentTicket, props.continuationToken) &&
      isEqual(filters, initialFilters)
    )
  )

  const handleUpdate = (): void => {
    const payload: PatchFocalscopeSftpPayload = {
      continuationToken: mostRecentTicket,

      filters: filters,
    }

    mutate(
      { uid, payload },
      {
        onSuccess: () => {
          toast({
            title: t`FocalscopeSftp importer configuration updated`,
            status: 'success',
          })
        },
        onError: (error) => {
          toast({
            title: t`Could not update FocalscopeSftp importer configuration`,
            description: error.message,
            status: 'error',
          })
        },
      },
    )
  }

  return (
    <Card p="4" key={uid}>
      <Flex mb="8">
        <Heading>Focalscope Sftp Importer Configuration</Heading>
      </Flex>
      <Grid gridTemplateColumns="1fr 1fr" gap="8">
        <FormControl>
          <FormLabel htmlFor="friendlyName">
            <Trans>Last imported ticket</Trans>
          </FormLabel>
          <Input
            size="sm"
            onChange={(e) => setMostRecentTicket(e.currentTarget.value)}
            value={mostRecentTicket}
            isDisabled={isPending}
          />
        </FormControl>

        <DirectionSelector
          isDisabled={isPending}
          onChange={setFilters.whitelistedDirections.onChange}
          initialDirections={setFilters.whitelistedDirections.value}
        />

        <FormControl>
          <FormLabel>
            <Trans>Blacklisted Subject Prefix</Trans>
          </FormLabel>
          <ChipsInput
            {...setFilters.blacklistedSubjectPrefix}
            placeholder={t`Enter Subject prefix ...`}
          />
        </FormControl>
        <FormControl>
          <FormLabel>
            <Trans>Blacklisted e-mails</Trans>
          </FormLabel>
          <ChipsInput
            {...setFilters.blacklistedEmails}
            placeholder={t`Enter e-mail ...`}
          />
        </FormControl>
        <FormControl>
          <FormLabel>
            <Trans>Blacklisted e-mail domains (capturi.com, example.com)</Trans>
          </FormLabel>
          <ChipsInput
            {...setFilters.blacklistedDomains}
            placeholder={t`Enter domain ...`}
          />
        </FormControl>
        <FormControl>
          <FormLabel>
            <Trans>Whitelisted Folders</Trans>
          </FormLabel>
          <ChipsInput
            splitOnWhiteSpace={false}
            {...setFilters.whitelistedFolders}
            placeholder={t`Enter full folder name ...`}
          />
        </FormControl>
        <FormControl>
          <FormLabel>
            <Trans>Blacklisted Folders</Trans>
          </FormLabel>
          <ChipsInput
            splitOnWhiteSpace={false}
            {...setFilters.blacklistedFolders}
            placeholder={t`Enter full folder name ...`}
          />
        </FormControl>
      </Grid>

      <Flex mt="4" justifyContent="space-between">
        <Button
          colorScheme={isActive ? 'red' : 'green'}
          isLoading={isLoadingStart || isLoadingStop}
          onClick={isActive ? () => stop(uid) : () => start(uid)}
        >
          {isActive ? t`Stop` : t`Start`}
        </Button>
        <Tooltip label={t`No changes to update`} isDisabled={isDirty}>
          <Button
            isDisabled={!isDirty || isLoadingStart || isLoadingStop}
            onClick={handleUpdate}
            primary
            isLoading={isPending}
          >
            <Trans>Update Configuration</Trans>
          </Button>
        </Tooltip>
      </Flex>
    </Card>
  )
}

export default Config
