import Icon_EmptyState from '@capturi/assets/images/EmptyState.svg'
import { Team, useCurrentUser } from '@capturi/core'
import {
  Button,
  ContentPlaceholder,
  SortDirectionArrow,
} from '@capturi/ui-components'
import {
  Box,
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React, { useState } from 'react'
import { MdAdd, MdEdit } from 'react-icons/md'
import { Column, Row, useSortBy, useTable } from 'react-table'

import { useFeatureFlags } from '@capturi/feature-flags'
import { TableWrapper, TableWrapperHeader } from '../../components/TableWrapper'
import { useTeamsApiContext } from '../../hooks/useTeamsApi'
import CreateUpdateTeam from './CreateUpdateTeam'

const HeaderLabelHelper = (label: string): JSX.Element => (
  <Text pl="4" textAlign="start" display="inline-block">
    {label}
  </Text>
)

const Teams: React.FC = () => {
  const { teams } = useTeamsApiContext()
  const user = useCurrentUser()
  const { disableUserConfig } = useFeatureFlags()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedTeamUid, setSelectedTeamUid] = useState<string | null>(null)
  const [selectedTeamName, setSelectedTeamName] = useState<string | null>(null)

  const handleEdit = (uid: string, name: string): void => {
    setSelectedTeamUid(uid)
    setSelectedTeamName(name)
    onOpen()
  }

  const handleClose = (): void => {
    setSelectedTeamUid(null)
    setSelectedTeamName(null)
    onClose()
  }

  const columns: Column<Team>[] = React.useMemo(
    () => [
      {
        accessor: 'name',
        id: 'team',
        width: 150,
        Header: HeaderLabelHelper(t`Team`),
      },
      {
        accessor: 'members',
        id: 'members',
        width: 150,
        Header: HeaderLabelHelper(t`Members`),
      },
      {
        accessor: 'uid',
        id: 'edit',
        width: 50,
      },
    ],
    [],
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: teams,
        sortTypes: React.useMemo(
          () => ({
            alphanumeric: (
              row1: Row<Team>,
              row2: Row<Team>,
              columnId: string,
            ) => {
              let a = row1.values[columnId]
              let b = row2.values[columnId]

              if (columnId === 'members') {
                a = row1.values[columnId].length
                b = row2.values[columnId].length
              }

              return String(a).localeCompare(String(b), user.language, {
                numeric: true,
                sensitivity: 'base',
              })
            },
          }),
          [user.language],
        ),
        autoResetSortBy: false,
        disableSortRemove: true,
        initialState: {
          sortBy: [
            {
              id: 'name',
              desc: false,
            },
          ],
        },
      },
      useSortBy,
    )

  return (
    <Box>
      {teams.length === 0 ? (
        <NoTeamsPlaceholder onAddTeam={onOpen} />
      ) : (
        <TableWrapper>
          <TableWrapperHeader title={t`Teams`}>
            {!disableUserConfig && (
              <Button primary onClick={onOpen} size="sm" float="right" my={2}>
                <Trans>Add team</Trans>
              </Button>
            )}
          </TableWrapperHeader>
          <Box width="full" overflowX="auto">
            <Table {...getTableProps()} variant="bordered">
              <Thead color="textMuted">
                {headerGroups.map((headerGroup) => (
                  // biome-ignore lint/correctness/useJsxKeyInIterable: spread
                  <Tr {...headerGroup.getHeaderGroupProps()} _hover={undefined}>
                    {headerGroup.headers.map((column) => (
                      // biome-ignore lint/correctness/useJsxKeyInIterable: spread
                      <Th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps(),
                        )}
                        title={undefined}
                        pl="0"
                      >
                        {column.render('Header')}
                        {column.isSorted && (
                          <SortDirectionArrow
                            isSortedDesc={column.isSortedDesc}
                            mx={1}
                            display="inline"
                          />
                        )}
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row)

                  return (
                    <Tr key={row.id}>
                      <Td as="td" whiteSpace="nowrap">
                        {row.values.team}
                      </Td>
                      <Td>
                        {row.values.members
                          ?.map((member: Team) => member.name)
                          .join(', ')}
                      </Td>
                      <Td>
                        <Tooltip
                          hasArrow
                          isDisabled={!row.original.createdByIntegration}
                          label={t`This is an auto generated team created by ${row.original.integrationType} (${row.original.externalUid}).`}
                          shouldWrapChildren
                          mt="3"
                        >
                          <IconButton
                            isDisabled={
                              row.original.createdByIntegration ||
                              disableUserConfig
                            }
                            size="sm"
                            isRound
                            icon={<MdEdit />}
                            onClick={() =>
                              handleEdit(row.original.uid, row.original.name)
                            }
                            aria-label={t`Edit`}
                            title={t`Edit`}
                          />
                        </Tooltip>
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          </Box>
        </TableWrapper>
      )}
      {isOpen && (
        <CreateUpdateTeam
          onClose={handleClose}
          uid={selectedTeamUid}
          name={selectedTeamName}
        />
      )}
    </Box>
  )
}

export default Teams

const NoTeamsPlaceholder: React.FC<{
  onAddTeam: () => void
}> = ({ onAddTeam }) => {
  return (
    <ContentPlaceholder.Container mt="10vh">
      <ContentPlaceholder.Image as={Icon_EmptyState} />
      <ContentPlaceholder.Heading>
        <Trans>It&apos;s a bit empty here!</Trans>
      </ContentPlaceholder.Heading>
      <ContentPlaceholder.Body>
        <Text>
          <Trans>Use teams to filter your conversations.</Trans>
        </Text>
        <Text mt={4}>
          <Trans>
            It is especially useful when you have multiple departments or teams
            who have different goals that you would like to filter upon.
          </Trans>
        </Text>
      </ContentPlaceholder.Body>
      <ContentPlaceholder.Footer>
        <Button primary onClick={onAddTeam} leftIcon={<MdAdd />}>
          <Trans>Add team</Trans>
        </Button>
      </ContentPlaceholder.Footer>
    </ContentPlaceholder.Container>
  )
}
