import { useModal } from '@capturi/use-modal'
import { HStack, IconButton, Td, Tr, useToast } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import React from 'react'
import { MdCopyAll, MdDelete, MdEdit, MdOpenInNew } from 'react-icons/md'
import { useCopyToClipboard } from 'react-use'
import { SummaryWidgetConfig } from '../../Api/SummaryWidget/useSummaryWidgetConfig'
import DeleteSummaryWidgetModal from '../Modals/DeleteSummaryWidgetModal'
import SummaryWidgetModal from '../Modals/SummaryWidgetModal'

const getWidgetUrl = ({
  organizationUid,
  token,
  widgetType,
}: { widgetType: string; token: string; organizationUid: string }): string => {
  switch (widgetType.toLowerCase()) {
    case 'puzzel':
      return `https://summary-widget.capturi.ai/puzzel?token=${token}&org=${organizationUid}`
    case 'adversus':
      return `https://summary-widget.capturi.ai?token=${token}&org=${organizationUid}&adversus-lead-id=[lead_id]`
    case 'genesys':
      return `https://summary-widget.capturi.ai?token=${token}&org=${organizationUid}&customer={{Scripter.Customer Formatted Number}}`
    case 'iframe':
      return `https://summary-widget.capturi.ai?token=${token}&org=${organizationUid}&customer=`
    default:
      return `https://summary-widget.capturi.ai/puzzel?token=${token}&org=${organizationUid}`
  }
}

const SummaryWidgetTableRow: React.FC<SummaryWidgetConfig> = ({
  createdBy,
  daysToLookBack,
  maxNumberOfSummeries,
  name,
  organizationUid,
  token,
  uid,
  waitForConnectionBeforeShowingSummary,
  widgetType,
}) => {
  const [, copy] = useCopyToClipboard()
  const toast = useToast()
  const [openDelete] = useModal(DeleteSummaryWidgetModal)
  const [openSummaryModal] = useModal(SummaryWidgetModal)

  return (
    <Tr>
      <Td>{name}</Td>
      <Td>{createdBy}</Td>
      <Td>{daysToLookBack}</Td>
      <Td>{maxNumberOfSummeries}</Td>
      <Td>{widgetType}</Td>
      <Td>
        <HStack>
          <IconButton
            size="xs"
            variant="ghost"
            icon={<MdEdit />}
            aria-label={t`Copy webhook to clipboard`}
            onClick={() => {
              openSummaryModal({
                currentUserName: name,
                config: {
                  name,
                  uid,
                  daysToLookBack: String(daysToLookBack),
                  maxNumberOfSummeries: String(maxNumberOfSummeries),
                  waitForConnectionBeforeShowingSummary:
                    waitForConnectionBeforeShowingSummary,
                  widgetType: widgetType,
                },
              })
            }}
          />
          <IconButton
            size="xs"
            variant="ghost"
            icon={<MdDelete />}
            aria-label={t`Delete Summary widget`}
            onClick={() => openDelete({ name: name, uid: uid })}
          />
          <IconButton
            size="xs"
            variant="ghost"
            icon={<MdCopyAll />}
            aria-label={t`Copy Summary widget url to clipboard`}
            onClick={() => {
              copy(getWidgetUrl({ organizationUid, token, widgetType }))
              toast({
                status: 'success',
                title: t`Copied Summary widget url to clipboard.`,
              })
            }}
          />
          <IconButton
            size="xs"
            variant="ghost"
            icon={<MdOpenInNew />}
            aria-label={t`Open Summary widget in new window`}
            as={'a'}
            href={`https://summary-widget.capturi.ai/demo?token=${token}&org=${organizationUid}&customer=`}
            target="_blank"
          />
        </HStack>
      </Td>
    </Tr>
  )
}

export default SummaryWidgetTableRow
