import { Button, Card, useToast } from '@capturi/ui-components'
import {
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Input,
  Stack,
  Tooltip,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { lightFormat } from 'date-fns/fp'
import React, { useState } from 'react'
import isEqual from 'react-fast-compare'
import { ChipsInput } from '../ChipsInput'
import DirectionSelector from '../DirectionSelector'
import CustomFieldSelector from './CustomFieldsSelector'
import WhitelistedGroupsSelector from './WhitelistedGroupsSelector'
import {
  ListZendeskResponse,
  PatchZendeskPayload,
  useStartZendeskImporter,
  useStopZendeskImporter,
  useUpdateZendeskImporter,
} from './useZendeskImporter'

const formatDate = lightFormat("yyy-MM-dd'T'HH:mm")

type Props = ListZendeskResponse

const Config: React.FC<Props> = (props) => {
  const { filters, isActive, uid } = props
  const { mutate, isPending } = useUpdateZendeskImporter()
  const { mutate: start, isPending: isLoadingStart } = useStartZendeskImporter()
  const { mutate: stop, isPending: isLoadingStop } = useStopZendeskImporter()
  const [customFields, setCustomFields] = useState<number[]>(props.customFields)
  const [continuationToken, setContinuationToken] = useState<string>(
    formatDate(props.continuationToken),
  )

  const toast = useToast()
  const [whitelistedGroupIds, setWhitelistedGroupIds] = useState<number[]>(
    filters.whitelistedGroupIds,
  )
  const [blacklistedSenderDomains, setBlacklistedSenderDomains] = useState<
    string[]
  >(filters.blacklistedSenderDomains)

  const [blacklistedSenderEmails, setBlacklistedSenderEmails] = useState(
    filters.blacklistedSenderEmails,
  )

  const [whitelistedDirections, setWhitelistedDirections] = useState<string[]>(
    filters.whitelistedDirections,
  )

  const isDirty = !(
    isEqual(filters.blacklistedGroupIds, whitelistedGroupIds) &&
    isEqual(filters.blacklistedSenderDomains, blacklistedSenderDomains) &&
    isEqual(filters.blacklistedSenderEmails, blacklistedSenderEmails) &&
    isEqual(filters.whitelistedDirections, whitelistedDirections) &&
    isEqual(filters.whitelistedGroupIds, whitelistedGroupIds) &&
    isEqual(customFields, props.customFields) &&
    // it would be better to compare the date-objects, but the html date-picker doesn't have seconds
    isEqual(continuationToken, formatDate(props.continuationToken))
  )

  const handleUpdate = (): void => {
    const payload: PatchZendeskPayload = {
      continuationToken: new Date(continuationToken),
      filters: {
        whitelistedGroupIds,
        blacklistedSenderDomains,
        blacklistedSenderEmails,
        whitelistedDirections,
      },
      customFields: customFields,
    }

    mutate(
      { uid, payload },
      {
        onSuccess: () => {
          toast({
            title: t`Zendesk importer configuration updated`,
            status: 'success',
          })
        },
        onError: (error) => {
          toast({
            title: t`Could not update Zendesk importer configuration`,
            description: error.message,
            status: 'error',
          })
        },
      },
    )
  }

  return (
    <Card p="4" key={uid}>
      <Flex mb="8">
        <Heading>Zendesk Importer Configuration</Heading>
      </Flex>
      <Grid gridTemplateColumns="1fr 1fr" gap="8">
        <Stack spacing="8">
          <FormControl>
            <FormLabel htmlFor="friendlyName">
              <Trans>Last imported ticket</Trans>
            </FormLabel>
            <Input
              size="sm"
              type="datetime-local"
              onChange={(e) => setContinuationToken(e.currentTarget.value)}
              value={continuationToken}
              isDisabled={isPending}
            />
          </FormControl>

          <CustomFieldSelector
            isDisabled={isPending}
            onChange={setCustomFields}
            integrationUid={uid}
            initialCostumFields={customFields}
          />
        </Stack>
        <WhitelistedGroupsSelector
          isDisabled={isPending}
          onChange={setWhitelistedGroupIds}
          integrationUid={uid}
          initialGroupIds={whitelistedGroupIds}
        />
        <DirectionSelector
          isDisabled={isPending}
          onChange={setWhitelistedDirections}
          initialDirections={whitelistedDirections}
        />

        <FormControl>
          <FormLabel>
            <Trans>Blacklisted e-mails</Trans>
          </FormLabel>
          <ChipsInput
            onChange={setBlacklistedSenderEmails}
            value={blacklistedSenderEmails}
            placeholder={t`Enter e-mail ...`}
          />
        </FormControl>
        <FormControl>
          <FormLabel>
            <Trans>Blacklisted e-mail domains (capturi.com, example.com)</Trans>
          </FormLabel>
          <ChipsInput
            onChange={setBlacklistedSenderDomains}
            value={blacklistedSenderDomains}
            placeholder={t`Enter domain ...`}
          />
        </FormControl>
      </Grid>

      <Flex mt="4" justifyContent="space-between">
        <Button
          colorScheme={isActive ? 'red' : 'green'}
          isLoading={isLoadingStart || isLoadingStop}
          onClick={isActive ? () => stop(uid) : () => start(uid)}
        >
          {isActive ? t`Stop` : t`Start`}
        </Button>
        <Tooltip label={t`No changes to update`} isDisabled={isDirty}>
          <Button
            isDisabled={!isDirty || isLoadingStart || isLoadingStop}
            onClick={handleUpdate}
            primary
            isLoading={isPending}
          >
            <Trans>Update Configuration</Trans>
          </Button>
        </Tooltip>
      </Flex>
    </Card>
  )
}

export default Config
