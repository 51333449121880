import { useModal } from '@capturi/use-modal'
import { Button, IconButton, Td, Tr, useToast } from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import React from 'react'
import { MdReplay } from 'react-icons/md'
import { WebhookEvent } from '../../Api/Webhooks/models/Webhooks'
import { useReplayWebhookEvent } from '../../Api/Webhooks/useWebhooks'
import { RawDataModal } from './RawDataModal'

type Props = {} & WebhookEvent

const date: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
}

const WebhookLogTableRow: React.FC<Props> = ({ ...props }) => {
  const toast = useToast()
  const [openLastResponseModal] = useModal(RawDataModal)
  const { mutate: replayEvent, isPending } = useReplayWebhookEvent()
  const handleReplay = (uid: string): void => {
    replayEvent(uid, {
      onSuccess: () => {
        toast({
          title: t`Successfully replayed event`,
          status: 'success',
        })
      },
      onError: (error) => {
        toast({
          title: t`Failed to replay event. Try again`,
          status: 'error',
          description: error.message,
        })
      },
    })
  }

  return (
    <Tr>
      <Td>{props.url ? props.url : t`No Call URL`}</Td>
      <Td>
        {props.lastRequest
          ? i18n.date(new Date(props.lastRequest), date)
          : t`No last request`}
      </Td>
      <Td>{props.lastOutcome ? props.lastOutcome : t`No last outcome`}</Td>

      <Td>
        <Button onClick={() => openLastResponseModal({ ...props })}>
          <Trans>See raw data</Trans>
        </Button>
      </Td>
      <Td>{props.lastResponseCode ? props.lastResponseCode : t`N/A`}</Td>
      <Td>
        <IconButton
          isLoading={isPending}
          aria-label={t`Replay webhook event`}
          onClick={() => handleReplay(props.uid)}
          icon={<MdReplay />}
        />
      </Td>
    </Tr>
  )
}

export default WebhookLogTableRow
