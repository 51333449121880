import analytics from '@capturi/analytics'
import { EmptyStateIcon } from '@capturi/react-utils'
import request, { downloadBlob } from '@capturi/request'
import {
  Button,
  ContentPlaceholder,
  DateRangePicker,
  Spinner,
  useToast,
} from '@capturi/ui-components'
import {
  Box,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import { useQuery } from '@tanstack/react-query'
import { endOfDay, startOfDay, subMonths } from 'date-fns'
import React, { useState } from 'react'
import { useBoolean } from 'react-use'

import { TableWrapper, TableWrapperHeader } from '../components/TableWrapper'

type Audit = {
  date: Date
  ip: string
  message: string
  requestUrl: string
  responseStatus: string
  type: string
  userEmail: string
  userId: string
}

type GetAuditsResponseModel = { audits: Audit[] }

const url = 'audits'

const formatDate = (d: Date): string => {
  return i18n.date(d, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })
}

const AuditLog: React.FC = () => {
  const [date, setDate] = React.useState({
    from: startOfDay(subMonths(new Date(), 1)),
    to: endOfDay(new Date()),
  })

  const [CSVDate, setCSVDate] = React.useState({
    from: startOfDay(subMonths(new Date(), 12)),
    to: endOfDay(new Date()),
  })

  const toast = useToast()
  const [isDownloading, setIsDownloading] = useBoolean(false)

  const [dateRange, setDateRange] = useState(date)

  const handleExportData = async (): Promise<void> => {
    try {
      setIsDownloading(true)
      const req = {
        url: `${url}/csv`,
        method: 'post',
        query: {
          'api-version': '3.3',
        },
        json: {
          fromInclusive: CSVDate.from,
          toInclusive: CSVDate.to,
          sortDirection: 'descending',
        },
      }

      analytics.event('organization_audits_download')

      toast({
        title: t`Downloading CSV file`,
        status: 'info',
      })
      await downloadBlob(req, 'audits.csv')
      toast({
        title: t`Download complete`,
        status: 'success',
      })
    } catch (_e) {
      toast({
        title: t`Download failed`,
        status: 'error',
      })
    } finally {
      setIsDownloading(false)
    }
  }

  const { data, error, isLoading } = useQuery<GetAuditsResponseModel>({
    queryKey: [url, dateRange.from, dateRange.to],
    queryFn: () =>
      request.post(url, {
        query: { 'api-version': '3.3' },
        json: {
          fromInclusive: dateRange.from,
          toInclusive: dateRange.to,
          pageNumber: 0,
          pageSize: 100_000,
          sortDirection: 'descending',
        },
      }),
  })

  const formatDateTime = (d: Date): string => {
    return i18n.date(d, { dateStyle: 'medium', timeStyle: 'short' })
  }

  function selectRange(range: { from: Date; to: Date }): void {
    analytics.event('organization_audits_edit_preview_range')
    setDate(range)
  }

  if (error) {
    return (
      <ContentPlaceholder.Container mt={20}>
        <EmptyStateIcon />
        <ContentPlaceholder.Heading>
          <Trans>Whoops! There has been an error</Trans>
        </ContentPlaceholder.Heading>
        <ContentPlaceholder.Body>
          <Trans>Try reloading the page</Trans>
        </ContentPlaceholder.Body>
        <ContentPlaceholder.Footer>
          <Button primary onClick={() => location.reload()}>
            <Trans>Reload page</Trans>
          </Button>
        </ContentPlaceholder.Footer>
      </ContentPlaceholder.Container>
    )
  }

  return (
    <Box>
      <Box as="header" mb="4">
        <Flex justify="space-between">
          <Popover
            onClose={() => {
              setDateRange({
                from: startOfDay(date.from),
                to: endOfDay(date.to),
              })
            }}
          >
            <PopoverTrigger>
              <Button>
                {t`Period: ${formatDate(dateRange.from)} - ${formatDate(
                  dateRange.to,
                )}`}
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <DateRangePicker
                value={date}
                showInputs
                placeholderFrom={t`From`}
                placeholderTo={t`To`}
                onSelectDateRange={selectRange}
              />
            </PopoverContent>
          </Popover>
          <Popover>
            <PopoverTrigger>
              <Button isLoading={isDownloading} primary>
                Download CSV
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <DateRangePicker
                value={CSVDate}
                showInputs
                placeholderFrom={t`From`}
                placeholderTo={t`To`}
                onSelectDateRange={setCSVDate}
              />
              <Button
                isLoading={isDownloading}
                primary
                onClick={handleExportData}
                w="auto"
                m="2"
                mt="4"
              >
                Start downloading CSV
              </Button>
            </PopoverContent>
          </Popover>
        </Flex>
      </Box>
      {isLoading && <Spinner size="sm" mx="50%" my="20" />}
      {data && (
        <TableWrapper>
          <TableWrapperHeader
            title={t`Audit log`}
            tooltip={t`Log of selected events in Capturi. Not all events are included.`}
          />
          <Table variant="bordered" overflowX="scroll">
            <Thead>
              <Tr>
                <Th>userEmail</Th>
                <Th>userId</Th>
                <Th>type</Th>
                <Th>date</Th>
                <Th>ip</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.audits.map((audit, i) => (
                <Tr key={audit.userId + i}>
                  <Td>{audit.userEmail}</Td>
                  <Td maxW="150px">{audit.userId}</Td>
                  <Td maxW="150px">{audit.type}</Td>
                  <Td>{formatDateTime(audit.date)}</Td>
                  <Td>{audit.ip}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableWrapper>
      )}
    </Box>
  )
}
export default AuditLog
