import { useState } from 'react'

type Handle<T> = T extends infer O
  ? { [K in keyof O]: { value: O[K]; onChange: (val: O[K]) => void } }
  : never

export const useFilterInput = <const T extends object>(
  initialValue: T,
): [T, Handle<T>] => {
  const [value, setValue] = useState(initialValue)

  return [
    value,
    // biome-ignore lint/suspicious/noExplicitAny: legacy
    Object.keys(initialValue).reduce<any>((memo, key) => {
      memo[key] = {
        // biome-ignore lint/suspicious/noExplicitAny: legacy
        onChange: (val: any): void => {
          setValue((v) => ({ ...v, [key]: val }))
        },
        value: value[key as keyof T],
      }
      return memo
    }, {}),
  ]
}
