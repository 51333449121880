import {
  ActionMeta,
  OnChangeValue,
  Select,
  SelectOption,
} from '@capturi/ui-select'
import { FormControl, FormLabel } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React from 'react'

type Props = {
  initialDirections: string[]
  onChange: (directions: Direction[]) => void
  isDisabled: boolean
  options?: { value: string; label: string }[]
}

export type Direction = 'Inbound' | 'Outbound' | 'AutoReply'
const defaultOptions = [
  { value: 'Inbound', label: 'Inbound' },
  { value: 'Outbound', label: 'Outbound' },
  { value: 'AutoReply', label: 'AutoReply' },
]

const DirectionSelector: React.FC<Props> = ({
  onChange,
  initialDirections = [],
  isDisabled,
  options = defaultOptions,
}) => {
  const initialValue = initialDirections.map((c) => ({
    value: c,
    label: c,
  }))

  const handleChange = (
    values: OnChangeValue<SelectOption, boolean>,
    action: ActionMeta<SelectOption>,
  ): void => {
    if (
      ['select-option', 'remove-value', 'deselect-option'].includes(
        action.action,
      ) &&
      Array.isArray(values)
    ) {
      onChange(values.map((x) => x.value))
    }
  }

  return (
    <FormControl>
      <FormLabel>
        <Trans>Directions</Trans>
      </FormLabel>
      <Select
        isDisabled={isDisabled}
        defaultValue={initialValue}
        isMulti
        options={options}
        placeholder={t`Select Directions to import ...`}
        onChange={handleChange}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        hideSelectedOptions={false}
        backspaceRemovesValue
        isClearable={false}
        autoFocus={false}
        minMenuHeight={100}
        maxMenuHeight={612}
      />
    </FormControl>
  )
}

export default DirectionSelector
