import { useCurrentUser } from '@capturi/core'
import { useModal } from '@capturi/use-modal'
import {
  Box,
  Button,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import {
  TableWrapper,
  TableWrapperHeader,
} from 'pages/Organization/components/TableWrapper'
import React from 'react'
import { useSummaryWidgetConfig } from '../Api/SummaryWidget/useSummaryWidgetConfig'
import SummaryWidgetModal from '../Components/Modals/SummaryWidgetModal'
import SummaryWidgetTableRow from '../Components/Rows/SummaryWidgetTableRow'

const Widgets: React.FC = () => {
  const { name } = useCurrentUser()
  const { data } = useSummaryWidgetConfig()
  const [openSummaryModal] = useModal(SummaryWidgetModal)
  return (
    <Box>
      <Box width="full" overflowX="auto" mb={4}>
        <TableWrapper>
          <TableWrapperHeader
            title={t`Summary widgets`}
            tooltip={t`A widget to monitor summaries`}
          >
            <Button
              colorScheme="primary"
              onClick={() => openSummaryModal({ currentUserName: name })}
            >
              <Trans>Create widget</Trans>
            </Button>
          </TableWrapperHeader>
          <Table variant="bordered">
            <Thead>
              <Tr>
                <Th>
                  <Trans>Name</Trans>
                </Th>
                <Th>
                  <Trans>Created</Trans>
                </Th>
                <Th>
                  <Trans>Days to look back</Trans>
                </Th>
                <Th>
                  <Trans>Summaries to show</Trans>
                </Th>
                <Th>
                  <Trans>Type</Trans>
                </Th>
                <Th w="5%" />
              </Tr>
            </Thead>
            <Tbody>
              {!data ? (
                <Tr w="100%">
                  <Td w="100%">
                    <Text fontSize="md" fontWeight="bold" textAlign="center">
                      <Trans>No content. Start by creating a widget</Trans>
                    </Text>
                  </Td>
                </Tr>
              ) : (
                <>
                  {data?.map((widget) => (
                    <SummaryWidgetTableRow key={widget.uid} {...widget} />
                  ))}
                </>
              )}
            </Tbody>
          </Table>
        </TableWrapper>
      </Box>
    </Box>
  )
}

export default Widgets
