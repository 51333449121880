import request, { ResponseError } from '@capturi/request'
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

const CACHE_KEY = ['importers', 'Dixa']

export type DixaFilters = {
  blacklistedSenderDomains: string[]
  blacklistedSenderEmails: string[]
  whitelistedQueueIds: string[]
  blacklistedQueuesIds: string[]
  whitelistedDirections: string[]
  number: number[]
}

export type ListDixaResponse = {
  auth: Record<string, never>
  filters: DixaFilters
  lastRunStarted: Date
  lastRunEnded: Date
  continuationToken: Date
  customFields: string[]
  friendlyName: string
  uid: string
  organizationUid: string
  isActive: boolean
}

export type AddDixaPayload = {
  auth: {
    token: string
  }
  friendlyName: string
  continuationToken: Date
}
export type PatchDixaPayload = Partial<{
  auth: Partial<{
    token: string
  }>
  friendlyName: string
  continuationToken: Date
  customFields: string[]
  filters: Partial<DixaFilters>
}>

export const useDixaImporter = (): UseQueryResult<
  ListDixaResponse[],
  ResponseError
> =>
  useQuery({
    queryKey: CACHE_KEY,
    queryFn: () => request.get<ListDixaResponse[]>('importer/dixa/list'),
  })

export const useAddDixaImporter = (): UseMutationResult<
  ListDixaResponse,
  ResponseError,
  AddDixaPayload,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: CACHE_KEY,
    mutationFn: (model: AddDixaPayload) =>
      request.post('importer/dixa/add', { json: model }),
    onSuccess: (importer) => {
      queryClient.setQueryData<ListDixaResponse[]>(CACHE_KEY, (oldData) => {
        if (!oldData) {
          return [importer]
        }
        return [...oldData, importer]
      })
    },
  })
}

type UpdateImporter = { uid: string; payload: PatchDixaPayload }
export const useUpdateDixaImporter = (): UseMutationResult<
  ListDixaResponse,
  ResponseError,
  UpdateImporter,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: CACHE_KEY,
    mutationFn: ({ uid, payload }) =>
      request.patch(`importer/dixa/${uid}`, { json: payload }),
    onSuccess: (importer, { uid }) => {
      queryClient.setQueryData<ListDixaResponse[]>(CACHE_KEY, (oldData) => {
        if (!oldData) {
          return [importer]
        }

        return oldData.map((d) => (d.uid === uid ? { ...d, ...importer } : d))
      })
    },
  })
}
export const useStartDixaImporter = (): UseMutationResult<
  ListDixaResponse,
  ResponseError,
  string,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: CACHE_KEY,
    mutationFn: (uid: string) => request.post(`importer/dixa/${uid}/start`),
    onSuccess: (_, uid) => {
      queryClient.setQueryData<ListDixaResponse[]>(CACHE_KEY, (oldData) => {
        if (!oldData) {
          return []
        }
        return oldData.map((d) =>
          d.uid === uid ? { ...d, isActive: true } : d,
        )
      })
    },
  })
}
export const useStopDixaImporter = (): UseMutationResult<
  ListDixaResponse,
  ResponseError,
  string,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: CACHE_KEY,
    mutationFn: (uid: string) => request.post(`importer/dixa/${uid}/stop`),
    onSuccess: (_, uid) => {
      queryClient.setQueryData<ListDixaResponse[]>(CACHE_KEY, (oldData) => {
        if (!oldData) {
          return []
        }
        return oldData.map((d) =>
          d.uid === uid ? { ...d, isActive: false } : d,
        )
      })
    },
  })
}
