import React from 'react'

import { useTextImporter } from '../useTextImporter'
import Config from './Config'
import type { GenesysChatResponse } from './types'

const GenesysChat: React.FC = () => {
  const { data = [] } = useTextImporter<GenesysChatResponse>('genesys-chat')

  return data.map((d) => <Config key={d.uid} {...d} />)
}

export default GenesysChat
