import { useInput } from '@capturi/react-utils'
import request from '@capturi/request/src/request'
import { useOrganization } from '@capturi/stores'
import { Button } from '@capturi/ui-components'
import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { subDays } from 'date-fns'
import React, { useState } from 'react'

import { useAddZendeskImporter } from './useZendeskImporter'

type Props = {
  onClose: () => void
}

const AddZendesk: React.FC<Props> = ({ onClose }) => {
  const [state, handleSetState] = useInput({
    email: '',
    token: '',
    domain: '',
  })

  const { mutate: addImporter, isPending } = useAddZendeskImporter()
  const { name } = useOrganization()
  const [testInProgress, setTestInProgress] = useState(false)
  const [testError, setTestError] = useState('')

  const handleAdd = async (): Promise<void> => {
    setTestInProgress(true)
    setTestError('')
    const auth = {
      email: state.email,
      token: state.token,
      domain: state.domain.replaceAll('.zendesk.com', ''),
    }
    try {
      await request.post('importer/zendesk/test-auth', {
        json: { auth: auth },
      })

      addImporter(
        {
          auth: auth,
          friendlyName: `${name} - zendesk`,
          continuationToken: subDays(new Date(), 10),
        },
        {
          onSuccess: () => {
            onClose()
          },
        },
      )
    } catch (_error) {
      setTestInProgress(false)
      setTestError('Invalid authentication')
    }
  }
  const inProgress = isPending || testInProgress
  return (
    <Modal isOpen={true} onClose={onClose} closeOnEsc={!inProgress}>
      <ModalOverlay>
        <ModalContent>
          <Flex direction="column">
            <ModalHeader>
              <Trans>Zendesk importer</Trans>
            </ModalHeader>
          </Flex>
          {!inProgress && <ModalCloseButton />}
          <ModalBody>
            <Stack spacing={4}>
              <FormControl>
                <FormLabel htmlFor="domain">
                  <Trans>Sub domain (xxx.zendesk.com)</Trans>
                </FormLabel>

                <InputGroup>
                  <Input
                    isDisabled={isPending}
                    placeholder={t`Domain`}
                    {...handleSetState.domain}
                  />
                  <InputRightAddon>.zendesk.com</InputRightAddon>
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="Email">
                  <Trans>E-mail</Trans>
                </FormLabel>
                <Input
                  isDisabled={isPending}
                  placeholder={t`E-mail`}
                  {...handleSetState.email}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="Token">
                  <Trans>Token</Trans>
                </FormLabel>
                <Input
                  isDisabled={isPending}
                  placeholder={t`Token`}
                  {...handleSetState.token}
                />
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <div>
              <Stack isInline spacing={4}>
                <Button secondary isDisabled={inProgress} onClick={onClose}>
                  <Trans>Cancel</Trans>
                </Button>
                <Button
                  primary
                  isLoading={inProgress}
                  mr={3}
                  onClick={handleAdd}
                >
                  <Trans>Add Zendesk importer</Trans>
                </Button>
              </Stack>
              <Text textAlign="end" color="red">
                {testError}
              </Text>
            </div>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

export default AddZendesk
