import analytics from '@capturi/analytics'
import { useConversations } from '@capturi/api-conversations'
import { EmptyStateIcon, useDebounce } from '@capturi/react-utils'
import { downloadBlob } from '@capturi/request'
import { OrgType, useOrganization, useUsers } from '@capturi/stores'
import {
  Button,
  ContentPlaceholder,
  Spinner,
  useToast,
} from '@capturi/ui-components'
import {
  Box,
  Checkbox,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans, select, t } from '@lingui/macro'
import UserAvatar from 'components/UserAvatar'
import React, { useState } from 'react'
import { useBoolean, useToggle } from 'react-use'

import { TableWrapper, TableWrapperHeader } from '../components/TableWrapper'

const url = 'conversations/customerdata'

const formatDateTime = (d: Date): string => {
  return i18n.date(d, { dateStyle: 'medium', timeStyle: 'short' })
}

const CustomerDataTable: React.FC<{
  organizationType: OrgType
  phoneNumber: string | undefined
}> = ({ organizationType, phoneNumber }) => {
  const { getUserByUid } = useUsers()

  const { data, error, isLoading } = useConversations({
    filter: phoneNumber
      ? {
          customers: [phoneNumber],
        }
      : {},
    enabled: phoneNumber != null,
  })

  if (error) {
    return (
      <ContentPlaceholder.Container mt={20}>
        <EmptyStateIcon />
        <ContentPlaceholder.Heading>
          <Trans>Whoops! There has been an error</Trans>
        </ContentPlaceholder.Heading>
        <ContentPlaceholder.Body>
          <Trans>Try reloading the page</Trans>
        </ContentPlaceholder.Body>
        <ContentPlaceholder.Footer>
          <Button primary onClick={() => location.reload()}>
            <Trans>Reload page</Trans>
          </Button>
        </ContentPlaceholder.Footer>
      </ContentPlaceholder.Container>
    )
  }

  if (!phoneNumber) {
    return (
      <ContentPlaceholder.Container mt={20} mb={20}>
        <ContentPlaceholder.Heading>
          {select(organizationType, {
            public: '👆No citizen selected',
            other: '👆No customer selected',
          })}
        </ContentPlaceholder.Heading>
        <ContentPlaceholder.Body>
          {select(organizationType, {
            public: "Enter citizen's phone number to download their data.",
            other: "Enter customer's phone number to download their data.",
          })}
        </ContentPlaceholder.Body>
      </ContentPlaceholder.Container>
    )
  }

  if (isLoading || data == null) {
    return (
      <Flex width="100%" my={36} justifyContent="center">
        <Spinner delay={0} display="block" m="1rem auto" size="xl" />
      </Flex>
    )
  }

  if (data.pages[0].length === 0) {
    return (
      <ContentPlaceholder.Container mt={20} mb={20}>
        <EmptyStateIcon />
        <ContentPlaceholder.Heading>
          {select(organizationType, {
            public: 'No citizen found',
            other: 'No customer found',
          })}
        </ContentPlaceholder.Heading>
        <ContentPlaceholder.Body>
          <Trans>
            We don&apos;t have any data on that specific phone number.
          </Trans>
        </ContentPlaceholder.Body>
      </ContentPlaceholder.Container>
    )
  }

  return (
    <Table variant="bordered" overflowX="scroll">
      <Thead>
        <Tr>
          <Th>
            {select(organizationType, {
              public: 'Citizen',
              other: 'Customer',
            })}
          </Th>
          <Th>
            <Trans>Duration</Trans>
          </Th>
          <Th>
            <Trans>Queue / campaign</Trans>
          </Th>
          <Th>
            <Trans>Date</Trans>
          </Th>
          <Th>
            <Trans>Agent</Trans>
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.pages[0].map((conversation, i) => {
          const { name, profileImage } = getUserByUid(conversation.userUid)
          return (
            <Tr key={conversation.uid + i}>
              <Td>{conversation.customer}</Td>
              <Td maxW="150px">{conversation.duration}</Td>
              <Td maxW="150px">{conversation.subject}</Td>
              <Td>{formatDateTime(conversation.dateTime)}</Td>
              <Td>
                <Flex flex={1} align="center" gap={2}>
                  <UserAvatar
                    name={name}
                    profileImage={profileImage}
                    size="sm"
                  />
                  <Text fontWeight="medium" textTransform="capitalize">
                    {name}
                  </Text>
                </Flex>
              </Td>
            </Tr>
          )
        })}
      </Tbody>
    </Table>
  )
}

const CustomerData: React.FC = () => {
  const { organizationType } = useOrganization()

  const [customerPhoneNumber, setCustomerPhoneNumber] = useState('')
  const debouncedPhoneNumber = useDebounce(customerPhoneNumber.trim(), 500)
  const [removeAgent, toggleRemoveAgent] = useToggle(true)

  const toast = useToast()
  const [isDownloading, setIsDownloading] = useBoolean(false)

  const handleExportData = async (): Promise<void> => {
    try {
      setIsDownloading(true)
      const req = {
        url,
        method: 'get',
        query: {
          'api-version': '3.3',
          customerPhoneNumber,
          removeAgent,
        },
      }

      analytics.event('organization_customer_data_download')
      toast({
        title: select(organizationType, {
          public: 'Downloading citizen data',
          other: 'Downloading customer data',
        }),
        status: 'info',
      })
      const phoneNumber = customerPhoneNumber.replace(/(\W+)/gi, '-')
      await downloadBlob(req, `customer-data-${phoneNumber}.zip`)
      toast({
        title: t`Download complete`,
        status: 'success',
      })
    } catch (_e) {
      toast({
        title: t`Download failed`,
        status: 'error',
      })
    } finally {
      setIsDownloading(false)
    }
  }

  return (
    <Box>
      <Box as="header" mb="4">
        <Flex justify="space-between">
          <Box
            border="solid 1px"
            borderColor="border.light"
            borderRadius="md"
            height="100%"
            padding={1}
            flexDirection="column"
            minW={80}
            _focusWithin={{
              borderColor: 'primary.500',
              boxShadow: '0 0 0 1px var(--chakra-colors-primary-500)',
            }}
          >
            <Box
              as="input"
              fontSize="sm"
              px={2}
              my="6px"
              outline={0}
              placeholder={t`Phone number...`}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setCustomerPhoneNumber(e.target.value)
              }
              value={customerPhoneNumber}
              w="100%"
            />
          </Box>
          <Flex align="center" gap={4}>
            <Checkbox
              isDisabled={isDownloading}
              isChecked={!removeAgent}
              onChange={toggleRemoveAgent}
            >
              <Trans>Include the agents part of the conversation</Trans>
            </Checkbox>
            <Button
              isLoading={isDownloading}
              isDisabled={customerPhoneNumber.trim().length === 0}
              primary
              onClick={handleExportData}
            >
              {select(organizationType, {
                public: 'Download Citizen Data',
                other: 'Download Customer Data',
              })}
            </Button>
          </Flex>
        </Flex>
      </Box>

      <TableWrapper>
        <TableWrapperHeader
          tooltip={t`Download all conversations from a specific customer`}
          title={select(organizationType, {
            public: 'Citizen',
            other: 'Customer',
          })}
        />
        <CustomerDataTable
          phoneNumber={debouncedPhoneNumber}
          organizationType={organizationType}
        />
      </TableWrapper>
    </Box>
  )
}
export default CustomerData
