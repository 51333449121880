import { useModal } from '@capturi/use-modal'
import { Box, Button, Table, Tbody, Th, Thead, Tr } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import Link from 'components/Link'
import {
  TableWrapper,
  TableWrapperHeader,
} from 'pages/Organization/components/TableWrapper'
import React from 'react'
import { MdLaunch } from 'react-icons/md'
import { useApiTokens } from '../Api/ApiToken/useApiToken'
import ApiTokenModal from '../Components/Modals/ApiTokenModal'
import TokenTableRow from '../Components/Rows/TokenTableRow'

const ApiTokens: React.FC = () => {
  const { data } = useApiTokens()
  const [openApiTokenModal] = useModal(ApiTokenModal)
  return (
    <Box>
      <Box width="full" overflowX="auto">
        <TableWrapper>
          <TableWrapperHeader
            title={t`Api tokens`}
            tooltip={t`API tokens are valid for 12 months`}
          >
            <Button variant="ghost" color="primary.500" leftIcon={<MdLaunch />}>
              <Link to="https://api.capturi.ai/audio/swagger/docs/index.html?version=v2">
                <Trans>API documentation</Trans>
              </Link>
            </Button>
            <Button colorScheme="primary" onClick={() => openApiTokenModal()}>
              <Trans>Create API token</Trans>
            </Button>
          </TableWrapperHeader>
          <Table variant="bordered">
            <Thead>
              <Tr>
                <Th>
                  <Trans>Name</Trans>
                </Th>
                <Th>
                  <Trans>Issued</Trans>
                </Th>
                <Th>
                  <Trans>Expires</Trans>
                </Th>
                <Th w="5%" />
              </Tr>
            </Thead>
            <Tbody>
              {data?.map((token) => (
                <TokenTableRow key={token.token} {...token} />
              ))}
            </Tbody>
          </Table>
        </TableWrapper>
      </Box>
    </Box>
  )
}

export default ApiTokens
