import { BaseModalComponentProps } from '@capturi/use-modal'
import {
  Button,
  Divider,
  Flex,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'
import { WebhookEvent } from '../../Api/Webhooks/models/Webhooks'

export const RawDataModal: React.FC<WebhookEvent & BaseModalComponentProps> = ({
  isOpen,
  onClose,
  ...props
}) => {
  return (
    <Modal size="2xl" isCentered isOpen={isOpen ?? false} onClose={onClose}>
      <ModalOverlay>
        <ModalContent w="100%">
          <Flex direction="column">
            <ModalHeader borderBottom="1px solid" borderColor="gray.200">
              <Trans>Raw webhook data</Trans>
            </ModalHeader>
          </Flex>
          <ModalCloseButton size="lg" />
          <ModalBody maxH="900px" overflowY="auto" w="100%">
            <VStack align="flex-start">
              {props.lastResponse && (
                <>
                  <VStack align="flex-start">
                    <Heading>
                      <Trans>Last response from Webhook</Trans>
                    </Heading>
                    <Text fontFamily="monospace">{props.lastResponse}</Text>
                  </VStack>
                  <Divider my={4} />
                </>
              )}
              {props.payload && (
                <VStack align="flex-start">
                  <Heading>
                    <Trans>Payload</Trans>
                  </Heading>
                  <Text
                    fontFamily="monospace"
                    wordBreak="break-all"
                    whiteSpace="pre-wrap"
                  >
                    {props.payload}
                  </Text>
                </VStack>
              )}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <HStack spacing={4} justify="flex-end">
              <Button onClick={onClose}>
                <Trans>Close</Trans>
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
